import { Layout } from "../components/layout/landing/Layout";
import Weekly from "../components/weekly/Weekly";

const WeeklyPage = () => {
  return (
    <Layout>
      <Weekly />
    </Layout>
  );
};

export default WeeklyPage;

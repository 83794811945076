import classNames from "classnames";

interface LinkButtonProps {
  text: string;
  link: string;
  size?: "small" | "medium" | "large" | "default";
}

const LinkButton = ({ text, link, size = "default" }: LinkButtonProps) => {
  let defaultStyle = "";
  let defaultSizeIco = 20;
  switch (size) {
    case "small":
      defaultStyle = "gap-2 px-3 py-0 text-sm";
      defaultSizeIco = 16;
      break;
    case "medium":
      defaultStyle = "gap-2 px-3 py-2";
      break;
    case "large":
      defaultStyle = "gap-2 px-4 py-2";
      break;
    default:
      defaultStyle = "gap-2 px-4 py-2";
      break;
  }

  const handleCopy = () => {
    // copy to clipboard
    navigator.clipboard.writeText(link);
  };

  return (
    <button
      onClick={() => handleCopy()}
      className={classNames(
        "text-white rounded-2xl border-[3px] border-blue-dark#2 hover:border-blue-dark#3 active:border-blue-dark#2 flex items-center active:bg-blue-dark#2",
        defaultStyle
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={defaultSizeIco}
        height={defaultSizeIco}
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clip-path="url(#clip0_608_35965)">
          <path
            d="M6.6665 8.66663C6.95281 9.04939 7.31808 9.36609 7.73754 9.59526C8.157 9.82443 8.62084 9.96071 9.0976 9.99486C9.57437 10.029 10.0529 9.96021 10.5007 9.79315C10.9486 9.6261 11.3552 9.36468 11.6932 9.02663L13.6932 7.02663C14.3004 6.39796 14.6363 5.55595 14.6288 4.68196C14.6212 3.80797 14.2706 2.97193 13.6526 2.3539C13.0345 1.73588 12.1985 1.38531 11.3245 1.37772C10.4505 1.37013 9.60851 1.70611 8.97984 2.3133L7.83317 3.4533"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.33322 7.33327C9.04692 6.95052 8.68165 6.63382 8.26219 6.40465C7.84273 6.17548 7.37888 6.0392 6.90212 6.00505C6.42536 5.97091 5.94683 6.0397 5.49899 6.20675C5.05115 6.37381 4.64448 6.63523 4.30656 6.97327L2.30656 8.97327C1.69936 9.60195 1.36338 10.444 1.37098 11.3179C1.37857 12.1919 1.72913 13.028 2.34716 13.646C2.96519 14.264 3.80123 14.6146 4.67522 14.6222C5.54921 14.6298 6.39121 14.2938 7.01989 13.6866L8.15989 12.5466"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_608_35965">
            <rect width={defaultSizeIco} height={defaultSizeIco} fill="white" />
          </clipPath>
        </defs>
      </svg>
      {text}
    </button>
  );
};

export default LinkButton;

import classNames from "classnames";
import { useEffect, useState } from "react";
import MyProfile from "./MyProfile";
import MyProposal from "./MyProposal";
import MyVote from "./MyVote";
import { useNavigate } from "react-router-dom";
import useSearchQuery from "../../../hooks/useQuery";

const BottomSection = () => {
  const [nav, setNav] = useState("profile"); // [0, 1, 2]
  const navigate = useNavigate();
  let query = useSearchQuery();

  useEffect(() => {
    setNav(query.get("section") || "profile");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNav = (navi: string) => {
    navigate({
      pathname: "/app/profile",
      search: "?section=" + navi,
    });
    setNav(navi);
  };

  const ComponentToRender = () => {
    switch (nav) {
      case "profile":
        return <MyProfile />;
      case "votes":
        return <MyVote />;
      case "proposals":
        return <MyProposal />;
      default:
        return <div>My profile</div>;
    }
  };

  return (
    <div className="mt-4 w-full min-h-[300px] px-4 lg:px-24 py-12 bg-blue-dark#1 rounded-3xl flex-col justify-start items-start gap-2.5">
      <Navigation nav={nav} setNav={handleNav} />
      <ComponentToRender />
    </div>
  );
};

export default BottomSection;

interface NavigationProps {
  setNav: (nav: string) => void;
  nav: string;
}

const Navigation = ({ setNav, nav }: NavigationProps) => {
  return (
    <div className="w-full h-12 justify-center lg:justify-start items-center lg:items-start gap-8 inline-flex relative">
      <div
        className={classNames(
          {
            "border-b-3 border-primary": nav === "profile",
          },
          "flex-col justify-start items-center gap-6 inline-flex h-full z-10"
        )}
      >
        <button
          onClick={() => setNav("profile")}
          className={classNames(
            {
              "text-teal-500": nav === "profile",
              "text-slate-400": nav !== "profile",
            },
            "text-lg font-medium leading-normal"
          )}
        >
          My profile
        </button>
      </div>
      <div
        className={classNames(
          {
            "border-b-3 border-primary": nav === "votes",
          },
          "flex-col justify-start items-center gap-6 inline-flex h-full z-10"
        )}
      >
        <button
          onClick={() => setNav("votes")}
          className={classNames(
            {
              "text-teal-500": nav === "votes",
              "text-slate-400": nav !== "votes",
            },
            "text-lg font-medium leading-normal"
          )}
        >
          My votes
        </button>
      </div>
      <div
        className={classNames(
          {
            "border-b-3 border-primary": nav === "proposals",
          },
          "flex-col justify-start items-center gap-6 inline-flex h-full z-10"
        )}
      >
        <button
          onClick={() => setNav("proposals")}
          className={classNames(
            {
              "text-teal-500": nav === "proposals",
              "text-slate-400": nav !== "proposals",
            },
            "text-lg font-medium leading-normal"
          )}
        >
          My proposals
        </button>
      </div>
      <div className="absolute h-0.5 w-full rounded-lg bg-blue-dark#4 bottom-0 z-0" />
    </div>
  );
};

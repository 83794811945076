import classNames from "classnames";

interface IPrimaryButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  size?: "small" | "medium" | "large";
  loading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const PrimaryButton = ({ children, onClick, loading, size, disabled, style }: IPrimaryButtonProps) => {
  let buttonDefaultSize = "";

  switch (size) {
    case "small":
      buttonDefaultSize = "text-text#3 py-3 px-14";
      break;
    case "medium":
      buttonDefaultSize = "text-text#3 py-4 px-28";
      break;
    case "large":
      buttonDefaultSize = "text-text#2 py-5";
      break;
    default:
      buttonDefaultSize = "3xl:text-text#2 text-text#3 py-5";
      break;
  }

  return (
    <button
      className={classNames(
        {
          "cursor-not-allowed flex justify-center items-center": disabled || loading,
          "bg-primary hover:bg-primary_hover text-white": !disabled,
          "bg-blue-dark#5 text-grey-default": disabled,
        },
        "font-tommy font-bold rounded-2xl shadow__button active:shadow-none w-full lg:w-auto",
        buttonDefaultSize
      )}
      style={style}
      onClick={onClick}
    >
      {loading ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
          className="animate-spin"
        >
          <circle cx="16.5" cy="16" r="14" stroke="#0F998E" strokeWidth="4" />
          <mask id="path-2-inside-1_572_2085" fill="white">
            <path d="M30.4901 16C31.6001 16 32.513 16.9034 32.3739 18.0046C32.0101 20.8856 30.8669 23.627 29.05 25.9246C26.8156 28.7501 23.6931 30.7389 20.1879 31.5692C16.6826 32.3995 12.9998 32.0226 9.73533 30.4996C7.08089 29.2612 4.82942 27.3239 3.21192 24.9122C2.59363 23.9903 3.00428 22.7735 3.99636 22.2756C4.98844 21.7776 6.18376 22.1915 6.84036 23.0865C8.02455 24.7006 9.60131 26.0014 11.4348 26.8568C13.8791 27.9972 16.6367 28.2794 19.2613 27.6577C21.8859 27.036 24.224 25.5468 25.897 23.4312C27.152 21.8442 27.9778 19.9743 28.3121 18.0005C28.4974 16.9061 29.3801 16 30.4901 16Z" />
          </mask>
          <path
            d="M30.4901 16C31.6001 16 32.513 16.9034 32.3739 18.0046C32.0101 20.8856 30.8669 23.627 29.05 25.9246C26.8156 28.7501 23.6931 30.7389 20.1879 31.5692C16.6826 32.3995 12.9998 32.0226 9.73533 30.4996C7.08089 29.2612 4.82942 27.3239 3.21192 24.9122C2.59363 23.9903 3.00428 22.7735 3.99636 22.2756C4.98844 21.7776 6.18376 22.1915 6.84036 23.0865C8.02455 24.7006 9.60131 26.0014 11.4348 26.8568C13.8791 27.9972 16.6367 28.2794 19.2613 27.6577C21.8859 27.036 24.224 25.5468 25.897 23.4312C27.152 21.8442 27.9778 19.9743 28.3121 18.0005C28.4974 16.9061 29.3801 16 30.4901 16Z"
            stroke="#0A1939"
            strokeWidth="6.4"
            mask="url(#path-2-inside-1_572_2085)"
          />
        </svg>
      ) : (
        children
      )}
    </button>
  );
};

export default PrimaryButton;

import { useParams } from "react-router-dom";
import { Layout } from "../components/layout/landing/Layout";
import Partner from "../components/partner/Partner";

const PartnerPage = () => {
  const { id } = useParams();
  console.log('ici')
  if (!id) throw new Error("No id in URL");
  return (
    <Layout>
      <Partner id={id} />
    </Layout>
  );
};

export default PartnerPage;

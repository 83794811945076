import { Link } from "react-router-dom";
import StatusCard from "./Status";
import { SubmittedProposalDto } from "@justapes/api-types";

const CardProposal = ({ proposal }: { proposal: SubmittedProposalDto }) => {
  const descWithMaxChar = (desc: string, maxChar: number) => {
    if (desc.length > maxChar) return desc.slice(0, maxChar) + "...";
    return desc;
  };
  return (
    <Link
      to={"/app/proposal/" + proposal.proposalId}
      className="rounded-2xl bg-blue-dark#2 p-6 flex flex-col justify-between"
    >
      <div className="flex justify-between">
        <StatusCard status={proposal.state} />
        <div className="px-4 rounded-2xl border-[3px] border-blue-dark#3 justify-center items-center gap-2.5 inline-flex">
          <div className="text-white text-sm font-bold">#{proposal.proposalId}</div>
        </div>
      </div>
      <div className="flex flex-col mt-8 justify-between">
        <div className="text-white text-lg font-bold">{proposal.title}</div>
        <div
          className="text-slate-400 text-base font-medium leading-snug mt-4"
          dangerouslySetInnerHTML={{ __html: descWithMaxChar(proposal.description, 100) }}
        ></div>
      </div>
      <div className="mt-8 flex-col">
        <ProgessBar progress={proposal.votes.inFavorOf} />
        <div className="mt-2 flex justify-between">
          <span className="text-white text-sm font-bold">
            Yes <span className="text-slate-400 text-sm font-bold">({proposal.votes.inFavorOf}%)</span>
          </span>
          <span className="text-white text-sm font-bold">
            No <span className="text-slate-400 text-sm font-bold">({proposal.votes.against}%)</span>
          </span>
        </div>
      </div>
    </Link>
  );
};

export default CardProposal;

const ProgessBar = ({ progress }: { progress: number }) => {
  return (
    <div className="w-full bg-primary rounded-full h-3 shadow__button">
      <div className="bg-[#09ECDF] h-3 rounded-full" style={{ width: progress }}></div>
    </div>
  );
};

import { useCallback, useEffect, useState } from "react";
import { web3Enable, web3Accounts } from "@polkadot/extension-dapp";
import { getLocalStorage, setLocalStorage } from "../utils/localstorage";
import { ApiPromise, WsProvider } from "@polkadot/api";

export type InjectedExtension = Awaited<ReturnType<typeof web3Enable>>[number];
export type InjectedAccountWithMeta = Awaited<ReturnType<typeof web3Accounts>>[number];

function useWallet() {
  const [extensions, setExtensions] = useState<InjectedExtension[]>([]);
  const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);
  const [walletConnected, setWalletConnected] = useState<"pending" | "connected" | "disconnected">("pending");
  const [api, setApi] = useState<ApiPromise>();

  const loadAccountsFromExtensions = useCallback(async () => {
    // extension-dapp API: connect to extensions; returns list of injected extensions
    const injectedExtensions = await web3Enable("aleph-zero-signer");
    setExtensions(injectedExtensions);
    if (injectedExtensions.length > 0) {
      setWalletConnected("connected");
      setLocalStorage("JA_AAW", "connected");
    } else {
      setWalletConnected("disconnected");
      setLocalStorage("JA_AAW", "disconnected");
    }
  }, []);

  const loadAPI = async () => {
    const provider = new WsProvider("wss://ws.test.azero.dev");
    const api = await ApiPromise.create({ provider });
    setApi(api);
  };

  useEffect(() => {
    if (!api) loadAPI();
  }, [api]);

  useEffect(() => {
    const status = getLocalStorage("JA_AAW") || "disconnected";
    if (walletConnected !== "disconnected" && status !== "disconnected") {
      loadAccountsFromExtensions();
    } else {
      setWalletConnected("disconnected");
    }
  }, [extensions.length, loadAccountsFromExtensions, walletConnected]);

  useEffect(() => {
    const status = getLocalStorage("JA_AAW") || "disconnected";
    if (extensions.length > 0 && walletConnected === "connected" && status === "connected") {
      web3Accounts().then((injectedAccounts) => setAccounts(injectedAccounts));
    }
  }, [extensions, walletConnected]);

  const disconnect = () => {
    setLocalStorage("JA_AAW", "disconnected");
    setWalletConnected("disconnected");
    setAccounts([]);
    setExtensions([]);
  };

  // extension-dapp API: get accounts from extensions filtered by name

  return {
    extensions,
    loadAccountsFromExtensions,
    accounts,
    walletConnected,
    api,
    disconnect,
  };
}

export default useWallet;

import { ProposalState } from "@justapes/governance";

const StatusCard = ({ status }: { status: ProposalState }) => {
  let statusColor = status === ProposalState.Accepted ? "border-teal-500" : "border-slate-400";
  let statusTextColor = status === ProposalState.Accepted ? "text-teal-500" : "text-slate-400";
  return (
    <div
      className={
        "w-fit px-4 py-3 rounded-2xl border-[3px] justify-center items-center gap-2.5 inline-flex " + statusColor
      }
    >
      <div className={"text-sm font-bold " + statusTextColor}>{status}</div>
    </div>
  );
};

export default StatusCard;

import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from "recharts";
import Proposal from "../../../api/proposal";

// const data = [
//   { name: "Day 1", yes: 1, no: 1, abstain: 0 },
//   { name: "Day 2", yes: 3, no: 1, abstain: 2 },
//   { name: "Day 3", yes: 4, no: 10, abstain: 3 },
//   { name: "Day 4", yes: 6, no: 11, abstain: 5 },
//   { name: "Day 5", yes: 6, no: 11, abstain: 5 },
//   { name: "Day 6", yes: 6, no: 11, abstain: 5 },
//   { name: "Day 7", yes: 6, no: 11, abstain: 5 },
// ];

const ProposalChart = ({ id }: { id: string }) => {
  const [width, setWidth] = useState(0);
  const [timelineData, setTimelineData] = useState<{ name: string; yes: number; no: number; abstain: number }[]>([]); // [1]
  const { data: timeline } = useQuery(["timeline", id], () => Proposal.getProposalTimeline(id), {
    enabled: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!timeline) return;
    const timelineData = Object.entries(timeline).map(([name, value]) => {
      return {
        name,
        yes: value,
        no: value,
        abstain: value,
      };
    });
    setTimelineData(timelineData);
  }, [timeline]);

  useEffect(() => {
    // get size of content_chart
    const handleResize = () => {
      const contentChart = document.querySelector(".content_chart");
      const contentChartWidth = contentChart?.clientWidth || 0;
      setWidth(contentChartWidth);
    };

    // resize chart on window resize
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="content_chart w-full p-4 lg:py-8 rounded-2xl border-3 border-blue-dark#2 mt-4">
      <div className="justify-between items-center inline-flex w-full mb-4">
        <div className="text-slate-400 text-lg font-medium leading-normal">Votes cast in the period</div>
      </div>
      <LineChart width={width - 48} height={300} data={timelineData}>
        <Line type="step" dataKey="yes" stroke="#03B3A9" strokeWidth={"3px"} />
        <Line type="step" dataKey="no" stroke="#9957F5 " strokeWidth={"3px"} />
        <Line type="step" dataKey="abstain" stroke="#748FBE  " strokeWidth={"3px"} />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
      </LineChart>
    </div>
  );
};

export default ProposalChart;

import { Outlet } from "react-router-dom";
import Footer from "../Footer";
import Header from "./Header";

export const Layout = () => {
  return (
    <main className="w-full h-fit min-h-screen bg-blue-dark#5 layout__bg_app relative">
      <section className="max-w-screen-3xl m-auto font-tommy">
        <Header />
        <main className="pb-60 lg:px-5">
          <Outlet />
        </main>
        <Footer />
      </section>
    </main>
  );
};

interface Props {
  title: string;
  number: string;
  description: string;
}

const CardCore = ({ ...props }: Props) => {
  const { title, number, description } = props;

  return (
    <div className="w-full lg:w-96 h-96 p-10 bg-slate-900 rounded-3xl border border-blue-950 flex-col items-start inline-flex">
      <div className="opacity-20 text-slate-400 text-9xl font-bold font-tommy">{number}</div>
      <div className="self-stretch mt-5 flex-col justify-start items-start gap-4 flex">
        <div className="self-stretch text-white text-3xl font-bold font-tommy">{title}</div>
        <div className="self-stretch text-slate-400 text-base font-medium leading-snug font-tommy">{description}</div>
      </div>
    </div>
  );
};

export default CardCore;

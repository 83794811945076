import { CreateProposalResponseDto } from "@justapes/api-types";
import Share from "../../../assets/Share";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import SecondaryButton from "../../common/buttons/SecondaryButton";
import PreviewContent from "./PreviewContent";
import { Link } from "react-router-dom";

interface Props {
  form: {
    title: string;
    description: string;
    startDate: string;
    endDate: string;
  };
  handleClose: () => void;
  setStep: (step: number) => void;
  step: number;
  handleConfirm: () => void;
  loading?: boolean;
  submitedProposal?: CreateProposalResponseDto;
}

const Preview = ({ form, handleClose, setStep, step, handleConfirm, loading, submitedProposal }: Props) => {
  const handleCopy = () => {
    navigator.clipboard.writeText("https://localhost:3000/app/proposal" + submitedProposal?.proposal);
  };
  return (
    <div className="relative rounded-lg border-3 border-blue-dark#6 bg-blue-dark#1 p-4 xl:p-8 overflow-hidden ">
      {step === 3 && (
        <img
          src="/images/background/popup_success.png"
          alt="confirm"
          className="absolute -top-72 right-0 w-full z-0 opacity-30"
        />
      )}
      <div className="z-10 relative">
        <div className="flex justify-between items-center">
          <h3 className="text-white text-xl font-bold">{step === 2 && "Confirm new proposal"}</h3>
          <button
            onClick={handleClose}
            type="button"
            className="p-2 rounded-2xl border-3 border-blue-dark#3 flex-col justify-center items-center gap-2.5 inline-flex"
            data-modal-hide="defaultModal"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M18 6L6 18" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6 6L18 18" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        {step === 2 && (
          <p className="w-3/4 text-grey-default text-base font-medium leading-snug">
            Validate all information provided and confirm the proposal.
          </p>
        )}
        {step === 3 && (
          <div className="flex flex-col justify-center items-center">
            <div className="w-28 h-28 rounded-full border-4 border-teal-500 flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61" fill="none">
                <path
                  d="M50.5 15.5L23 43L10.5 30.5"
                  stroke="#03B3A9"
                  stroke-width="8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="mt-4 text-center text-white text-3xl font-bold">You successfully created new proposal!</div>
          </div>
        )}
        <div className="mt-8">
          <PreviewContent form={form} step={step} submitedProposal={submitedProposal} />
        </div>

        {step === 3 && (
          <div className="mt-8 w-full flex gap-4 ">
            <div className="w-1/2">
              <Link to={"/app/proposal/" + submitedProposal?.proposal}>
                <SecondaryButton style={{ width: "100%", height: "100%" }}>View proposal</SecondaryButton>
              </Link>
            </div>
            <div className="w-1/2">
              <PrimaryButton style={{ width: "100%" }} onClick={() => handleCopy()}>
                <span className="flex gap-2 justify-center">
                  <Share />
                  Share
                </span>
              </PrimaryButton>
            </div>
          </div>
        )}
        {step === 2 && (
          <>
            {/* <div className="mt-8 space-y-2">
              <div className="text-grey-default text-sm font-medium leading-tight flex justify-between">
                <span className="flex gap-2">Start date</span>
                <div className="flex">
                  <div className="text-white text-sm font-medium leading-tight">{form.startDate}</div>
                </div>
              </div>
              <div className="text-grey-default text-sm font-medium leading-tight flex justify-between">
                <span className="flex gap-2">End date</span>
                <div className="flex">
                  <div className="text-white text-sm font-medium leading-tight">{form.endDate}</div>
                </div>
              </div>
            </div> */}

            <div className="mt-8 w-full flex gap-4">
              <SecondaryButton onClick={() => setStep(1)}>Back</SecondaryButton>
              <PrimaryButton
                loading={loading}
                onClick={() => handleConfirm()}
                style={{
                  width: "100%",
                }}
              >
                Confirm
              </PrimaryButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Preview;

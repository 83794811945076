const Collectibles = () => {
  return (
    <section className="relative">
      <div className="text-white text-xl font-bold">Digital Collectibles</div>
      <div className="mt-8 w-full grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {Array.from({ length: 8 }).map((_, index) => (
          <div className="w-full relative bg-blue-950 rounded-2xl p-4 m-auto max-w-xs">
            <div className="flex flex-col h-full justify-between items-start gap-4">
              <img
                className="w-64 h-64 left-[8px] top-[8px] rounded-xl m-auto"
                src="https://via.placeholder.com/265x265"
                alt="collectible"
              />
              <div className="flex-col justify-center items-start gap-1 inline-flex">
                <div className="text-slate-400 text-sm font-medium leading-tight">JustApe</div>
                <div className="text-white text-lg font-bold">#4767</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Collectibles;

import Achievements from "./Achievements";
import Collectibles from "./Collectibles";

const MyProfile = () => {
  return (
    <section className="mt-12 lg:mt-8 relative flex flex-col lg:flex-row gap-6">
      <div className="lg:w-1/3">
        <Achievements />
      </div>
      <div className="lg:w-2/3">
        <Collectibles />
      </div>
    </section>
  );
};

export default MyProfile;

interface Props {
  size: number;
}

const Discord = ({ size }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size - 1} viewBox="0 0 25 24" fill="none">
      <path
        d="M19.6568 5.33005C18.3268 4.71005 16.8868 4.26005 15.3868 4.00005C15.3737 3.99963 15.3606 4.00209 15.3485 4.00728C15.3364 4.01246 15.3256 4.02023 15.3168 4.03005C15.1368 4.36005 14.9268 4.79005 14.7868 5.12005C13.1958 4.88005 11.5778 4.88005 9.98684 5.12005C9.84684 4.78005 9.63684 4.36005 9.44684 4.03005C9.43684 4.01005 9.40684 4.00005 9.37684 4.00005C7.87684 4.26005 6.44684 4.71005 5.10684 5.33005C5.09684 5.33005 5.08684 5.34005 5.07684 5.35005C2.35684 9.42005 1.60684 13.38 1.97684 17.3C1.97684 17.32 1.98684 17.34 2.00684 17.35C3.80684 18.67 5.53684 19.47 7.24684 20C7.27684 20.01 7.30684 20 7.31684 19.98C7.71684 19.43 8.07684 18.85 8.38684 18.24C8.40684 18.2 8.38684 18.16 8.34684 18.15C7.77684 17.93 7.23684 17.67 6.70684 17.37C6.66684 17.35 6.66684 17.29 6.69684 17.26C6.80684 17.18 6.91684 17.09 7.02684 17.01C7.04684 16.99 7.07684 16.99 7.09684 17C10.5368 18.57 14.2468 18.57 17.6468 17C17.6668 16.99 17.6968 16.99 17.7168 17.01C17.8268 17.1 17.9368 17.18 18.0468 17.27C18.0868 17.3 18.0868 17.36 18.0368 17.38C17.5168 17.69 16.9668 17.94 16.3968 18.16C16.3568 18.17 16.3468 18.22 16.3568 18.25C16.6768 18.86 17.0368 19.44 17.4268 19.99C17.4568 20 17.4868 20.01 17.5168 20C19.2368 19.47 20.9668 18.67 22.7668 17.35C22.7868 17.34 22.7968 17.32 22.7968 17.3C23.2368 12.77 22.0668 8.84005 19.6968 5.35005C19.6868 5.34005 19.6768 5.33005 19.6568 5.33005ZM8.90684 14.91C7.87684 14.91 7.01684 13.96 7.01684 12.79C7.01684 11.62 7.85684 10.67 8.90684 10.67C9.96684 10.67 10.8068 11.63 10.7968 12.79C10.7968 13.96 9.95684 14.91 8.90684 14.91ZM15.8768 14.91C14.8468 14.91 13.9868 13.96 13.9868 12.79C13.9868 11.62 14.8268 10.67 15.8768 10.67C16.9368 10.67 17.7768 11.63 17.7668 12.79C17.7668 13.96 16.9368 14.91 15.8768 14.91Z"
        fill="white"
      />
    </svg>
  );
};

export default Discord;

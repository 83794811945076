import { useState, useEffect } from "react";
import { CreateProposalResponseDto } from "@justapes/api-types";
import LinkToIco from "../../../assets/LinkToIco";
import { Link } from "react-router-dom";
import { useWalletContext } from "../../../context/useWalletContext";

interface Props {
  form: {
    title: string;
    description: string;
    startDate: string;
    endDate: string;
  };
  step: number;
  submitedProposal?: CreateProposalResponseDto;
}

const PreviewContent = ({ form, step, submitedProposal }: Props) => {
  const { accounts } = useWalletContext();
  const [transaction, setTransaction] = useState<string>();
  const [author, setAuthor] = useState("");

  useEffect(() => {
    if (!submitedProposal) return;
    const [startTransaction, endTransaction] = submitedProposal.response.txHash.split("").reduce(
      (acc, curr, index) => {
        if (index < 5) {
          acc[0] += curr;

          return acc;
        } else if (index > submitedProposal.response.txHash.length - 5) {
          acc[1] += curr;
        }
        return acc;
      },
      ["", ""]
    );

    setTransaction(`${startTransaction}...${endTransaction}`);
  }, [submitedProposal]);

  useEffect(() => {
    if (!accounts[0]) return;
    const [startAccount, endAccount] = accounts[0].address.split("").reduce(
      (acc, curr, index) => {
        if (index < 5) {
          acc[0] += curr;
        } else if (index > accounts[0].address.length - 5) {
          acc[1] += curr;
        }
        return acc;
      },
      ["", ""]
    );

    setAuthor(`${startAccount}...${endAccount}`);
  }, [accounts]);

  const desc = form.description.replace(/\n/g, "<br />");

  return (
    <div className="w-full p-6 bg-blue-dark#2 rounded-2xl flex-col 2xl:justify-center items-start gap-4 inline-flex ">
      <div className="self-stretch 2xl:h-20 flex-col 2xl:justify-start items-start gap-3 flex">
        <div className="text-white text-lg font-bold">{form.title}</div>
        <div className="justify-start 2xl:items-center gap-3 flex flex-col 2xl:flex-row">
          {step === 3 && (
            <div className="justify-start items-center gap-2 flex">
              <div className="text-grey-default text-sm font-medium leading-tight">Proposal:</div>
              <div className="text-white text-sm font-bold">{transaction}</div>
            </div>
          )}
          <div className="w-1 h-1 bg-grey-default rounded-full hidden 2xl:block" />
          <div className="justify-start items-center gap-2 flex">
            <div className="text-grey-default text-sm font-medium leading-tight">Author:</div>
            <div className="justify-start items-center gap-2 flex">
              <img className="w-5 h-5 rounded-full" src="https://via.placeholder.com/20x20" alt="pp" />
              <div className="text-white text-sm font-bold">{author}</div>
            </div>
          </div>
          {step === 3 && (
            <>
              <div className="w-1 h-1 bg-grey-default rounded-full  hidden 2xl:block" />
              <div className="justify-start items-center gap-2 flex">
                <div className="text-grey-default text-sm font-medium leading-tight">Txn:</div>
                <Link
                  to={"https://alephzero.subscan.io/extrinsic/" + submitedProposal?.response.txHash}
                  target={"_blank"}
                  className="justify-start items-center gap-2 flex"
                >
                  <div className="text-white text-sm font-bold flex gap-2 items-center">
                    {transaction}
                    <LinkToIco />
                  </div>
                </Link>
              </div>
            </>
          )}
        </div>
        <div className="w-full h-0.5 bg-blue-dark#3 rounded-full" />
      </div>
      <div className="self-stretch max-h-40  flex-col justify-start items-start gap-2 flex">
        <div className="text-grey-default text-sm font-medium leading-tight">Description</div>
        <div
          className="self-stretch text-white text-base font-medium leading-snug overflow-auto"
          dangerouslySetInnerHTML={{
            __html: desc,
          }}
        ></div>
      </div>
    </div>
  );
};

export default PreviewContent;

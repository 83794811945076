import { useState, useEffect } from "react";
import partnersData from "../../data/partners.json";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

interface PartnerProps {
  id: string;
  name: string;
  description: string;
  logo: string;
  website: string;
  type: string;
}

const Partner = ({ id }: { id: string }) => {
  const [partnerData, setPartnerData] = useState<PartnerProps | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(partnersData);
    const p = partnersData.find((partner) => partner.id.toLocaleLowerCase() === id);

    if (!p) {
      navigate("/partners");
    } else {
      setPartnerData(p);
    }
  }, [id, navigate]);

  if (!partnerData) return <div></div>;

  return (
    <section className="px-4 xl:px-10 py-6 mb-12">
      <div>
        {" "}
        <Link
          to="/partners"
          className="px-4 py-3 bg-blue-dark#6 rounded-2xl border border-blue-dark#2 flex-col justify-center items-start gap-2 inline-flex"
        >
          <div className="justify-start items-center gap-2 inline-flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M19 12H5" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 19L5 12L12 5" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <div className="text-white text-base font-bold">Back to Worlds</div>
          </div>
        </Link>
      </div>
      <div className="flex mt-8 gap-4 flex-col lg:flex-row">
        <div className="w-full lg:w-1/3">
          <div className="w-full p-10 bg-blue-dark#6 rounded-3xl flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="self-stretch flex-col justify-start items-center gap-6 flex">
              <div className="px-4 py-4 rounded-full border-2 border-cyan-900 justify-center items-center inline-flex">
                <img className="w-24 lg:w-36 2xl:w-44 2xl:h-44 rounded-full" src={partnerData.logo} alt="logo" />
              </div>
              <div className="lg:w-60 2xl:w-96 text-center text-white text-3xl font-bold">{partnerData.name}</div>
            </div>
          </div>
          <div className="mt-4 p-10 bg-blue-dark#6 rounded-3xl flex-col justify-start items-start gap-2.5 inline-flex w-full">
            <div className="flex justify-between w-full">
              <div className="text-grey-default text-base font-medium leading-snug">Type</div>
              <div className="text-primary text-base font-bold">{partnerData.type}</div>
            </div>
            <div className="flex justify-between w-full">
              <div className="text-grey-default text-base font-medium leading-snug">Website</div>
              {partnerData.website !== "N/A" ? (
                <Link to={partnerData.website} target="_blank" className="text-white text-base font-bold">
                  {partnerData.website}
                </Link>
              ) : (
                <span className="text-white text-base font-bold">N/A</span>
              )}
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/3">
          <div className="p-10 bg-blue-dark#6 rounded-3xl flex-col justify-start items-start gap-2.5 inline-flex  w-full">
            <div className="text-white text-xl font-bold">Description</div>
            <div
              className="text-slate-400 text-base font-medium leading-normal"
              dangerouslySetInnerHTML={{
                __html: partnerData.description,
              }}
            ></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partner;

const Card = ({
  text,
  fromImg,
  fromAuthor,
  picture,
  url
}: {
  text: string;
  fromImg: string;
  fromAuthor: string;
  picture: string;
  url: string;
}) => {
  return (
    <div className="border-4 border-blue-dark#3 rounded-[20px] p-4 bg-blue-dark#1 w-11/12 lg:w-80 2xl:w-[750px] 3xl:w-[750px]">
      <div className="flex justify-between">
        <div>
          <div className="flex items-center">
            <div>
              <img src={fromImg} alt="random" className="rounded-full w-11 h-11" />
            </div>
            <div className="flex flex-col ml-2">
              {/* <h1 className="text-white font-roboto text-base font-bold ">Card Title</h1> */}
              <div className="text-grey-default text-base font-normal font-roboto">{fromAuthor}</div>
            </div>
          </div>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="21" viewBox="0 0 512 512" fill="none">
            <path
            d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
            fill="white"
            />
          </svg>
        </div>
      </div>
      <div className="mt-4">
        <div
          className="text-white text-base font-normal leading-tight font-roboto"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></div>
        <img className="w-full relative rounded-2xl mt-4" src={picture} alt="pict" />
        <a href={url}>
          <div className="inline-flex items-center mt-4 gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
              <path
                d="M9.76094 17.4801H9.74846C7.44537 17.4374 0.800049 11.4561 0.800049 5.79093C0.800049 3.06939 3.05142 0.680054 5.61753 0.680054C7.65937 0.680054 9.03248 2.08346 9.76005 3.10492C10.4858 2.08523 11.8589 0.680054 13.9017 0.680054C16.4696 0.680054 18.72 3.06939 18.72 5.79182C18.72 11.4552 12.0738 17.4365 9.77075 17.4783H9.76094V17.4801ZM5.61842 2.01329C3.76383 2.01329 2.13839 3.77909 2.13839 5.79271C2.13839 10.8912 8.41012 16.0926 9.76183 16.1477C11.1153 16.0926 17.3853 10.892 17.3853 5.79271C17.3853 3.77909 15.7598 2.01329 13.9052 2.01329C11.6512 2.01329 10.3922 4.62113 10.3815 4.64689C10.1764 5.14608 9.35079 5.14608 9.14482 4.64689C9.13234 4.62025 7.87336 2.01329 5.61842 2.01329Z"
                fill="#748FBE"
              />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
              <path
                d="M10.2051 0.68856L6.67668 0.680054H6.67498C2.95431 0.680054 0.0400391 3.59517 0.0400391 7.31669C0.0400391 10.8026 2.75016 13.4464 6.39001 13.5859V16.8421C6.39001 16.9339 6.42744 17.0854 6.49209 17.1849C6.61288 17.3763 6.81873 17.4801 7.02969 17.4801C7.14708 17.4801 7.26532 17.4477 7.37164 17.3797C7.59621 17.2368 12.8778 13.8581 14.2516 12.6961C15.8695 11.3266 16.8375 9.31908 16.84 7.3269V7.31244C16.8349 3.59772 13.9224 0.68856 10.2051 0.687709V0.68856ZM13.4264 11.723C12.4618 12.5396 9.29067 14.6194 7.66596 15.6725V12.9615C7.66596 12.6093 7.381 12.3235 7.02799 12.3235H6.69114C3.57782 12.3235 1.31684 10.2173 1.31684 7.31669C1.31684 4.31055 3.67139 1.956 6.67583 1.956L10.2034 1.96451H10.2051C13.2095 1.96451 15.5641 4.31736 15.5658 7.32009C15.5632 8.9448 14.7636 10.5899 13.4264 11.723Z"
                fill="#748FBE"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Card;

import { Link } from "react-router-dom";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import Metadata from "../../common/Metadata";
import texts from "../../../configs/landing.json";

const Home = () => {
  return (
    <section className="lg:h-[80vh] mt-4 lg:mt-12 px-6 lg:px-36 flex flex-col text-white ml-0 justify-center relative py-6 ">
      <Metadata title="AAW plateform" />
      <div className="z-10">
        <h1 className="text-8xl font-bold z-10 font-tommy">
          Apes
          <br />
          Across
          <br />
          Worlds
        </h1>
        <p className="text-lg max-w-lg mt-4 text-grey-default font-tommy">{texts.description}</p>
        <div className="mt-4">
          <Link to="/app">
            <PrimaryButton size="small" style={{ paddingLeft: "1rem", paddingRight: "1rem", borderRadius: "12px" }}>
              Launch ApeVote
            </PrimaryButton>
          </Link>
        </div>
      </div>
      <img
        src="/images/home/worlds.png"
        alt="world"
        className="hidden lg:block lg:absolute bottom-0 -right-0 -top-[120px] z-0"
      />
      <img
        src="/images/home/world_mobile.png"
        alt="world"
        className="mt-8 lg:hidden bottom-0 -right-0 -top-[120px] z-0"
      />
    </section>
  );
};

export default Home;

import React, { useState } from "react";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import { toast } from "react-toastify";

interface Props {
  handleClose: () => void;
  form: {
    title: string;
    description: string;
    startDate: string;
    endDate: string;
  };
  setForm: (form: { title: string; description: string; startDate: string; endDate: string }) => void;
  setStep: (step: number) => void;
}

const CreateProposal = ({ form, setForm, handleClose, setStep }: Props) => {
  const [nbCharForTitle, setNbCharForTitle] = useState(0);
  const [nbCharForDesc, setNbCharForDesc] = useState(0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 255) return;
    setForm({ ...form, title: e.target.value });
    setNbCharForTitle(e.target.value.length);
  };

  const handleChangeDesc = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 32000) return;
    setForm({ ...form, description: e.target.value });
    setNbCharForDesc(e.target.value.length);
  };

  // const handleChangeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setForm({ ...form, startDate: e.target.value });
  // };

  // const handleChangeEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setForm({ ...form, endDate: e.target.value });
  // };
  return (
    <div className="relative rounded-lg border-3 border-blue-dark#6 bg-blue-dark#1 p-4 xl:p-8">
      <div className="flex justify-between items-center">
        <h3 className="text-white text-xl font-bold">Create a new proposal</h3>
        <button
          onClick={handleClose}
          type="button"
          className="p-2 rounded-2xl border-3 border-blue-dark#3 flex-col justify-center items-center gap-2.5 inline-flex"
          data-modal-hide="defaultModal"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18 6L6 18" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 6L18 18" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span className="sr-only">Close modal</span>
        </button>
      </div>
      <p className="w-3/4 text-grey-default text-base font-medium leading-snug">
        Provide all the necessary information so that users fully understand the proposal.
      </p>

      <div className="mt-8">
        <div className="text-grey-default text-sm font-medium leading-tight flex justify-between">
          <span className="flex gap-2">Title</span>
          <div className="flex">
            <div className="text-white text-sm font-medium leading-tight">{nbCharForTitle}</div>
            <div className="text-slate-400 text-sm font-medium leading-tight">/255</div>
          </div>
        </div>
        <div className="mt-2">
          <input
            type="text"
            onChange={(e) => handleChange(e)}
            value={form.title}
            className="w-full p-4 rounded-2xl border-3 border-blue-dark#3 bg-blue-dark#1 text-white text-sm font-medium leading-tight outline-none resize-none "
            placeholder="Type your proposal title..."
          />
        </div>
      </div>
      <div className="mt-8">
        <div className="text-grey-default text-sm font-medium leading-tight flex justify-between">
          <span className="flex gap-2">Description</span>
          <div className="flex">
            <div className="text-white text-sm font-medium leading-tight">{nbCharForDesc}</div>
            <div className="text-slate-400 text-sm font-medium leading-tight">/32000</div>
          </div>
        </div>
        <div className="mt-2">
          <textarea
            onChange={(e) => handleChangeDesc(e)}
            value={form.description}
            className="h-36 w-full p-4 rounded-2xl border-3 border-blue-dark#3 bg-blue-dark#1 text-white text-sm font-medium leading-tight outline-none resize-none "
            placeholder="Type your proposal title..."
          />
        </div>
      </div>
      {/* <div className="mt-8 flex justify-between gap-4">
        <div className="w-1/2">
          <div className="text-grey-default text-sm font-medium leading-tight flex justify-between">
            <span className="flex gap-2">Start date</span>
          </div>
          <div className="mt-2">
            <input
              onChange={(e) => handleChangeStartDate(e)}
              value={form.startDate}
              type="date"
              className="w-full p-4 rounded-2xl border-3 border-blue-dark#3 bg-blue-dark#1 text-white text-sm font-medium leading-tight outline-none resize-none "
            />
          </div>
        </div>
        <div className="w-1/2">
          <div className="text-grey-default text-sm font-medium leading-tight flex justify-between">
            <span className="flex gap-2">End date</span>
          </div>
          <div className="mt-2">
            <input
              onChange={(e) => handleChangeEndDate(e)}
              value={form.endDate}
              type="date"
              className="w-full p-4 rounded-2xl border-3 border-blue-dark#3 bg-blue-dark#1 text-white text-sm font-medium leading-tight outline-none resize-none "
            />
          </div>
        </div>
      </div> */}
      {/* <div className="mt-8 space-y-2">
        <div className="text-grey-default text-sm font-medium leading-tight flex justify-between">
          <span className="flex gap-2">Snapshot</span>
          <div className="flex">
            <div className="text-white text-sm font-medium leading-tight">24.151.1511</div>
          </div>
        </div>
        <div className="text-grey-default text-sm font-medium leading-tight flex justify-between">
          <span className="flex gap-2">Minimum Voting Power</span>
          <div className="flex">
            <div className="text-white text-sm font-medium leading-tight">1</div>
            <div className="text-slate-400 text-sm font-medium leading-tight">/2</div>
          </div>
        </div>
      </div> */}

      <div className="mt-8 w-full">
        <PrimaryButton
          onClick={() => {
            if (form.title.length < 15 || form.description.length < 20) {
              toast.error("Title and description must be at least 15 and 20 characters long respectively.");
              return;
            }
            setStep(2);
          }}
          style={{
            width: "100%",
          }}
          disabled={form.title.length === 0 || form.description.length === 0}
        >
          Continue
        </PrimaryButton>
      </div>
    </div>
  );
};

export default CreateProposal;

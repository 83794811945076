import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./tailwind.css";
import reportWebVitals from "./reportWebVitals";
import HomePage from "./routes/Home";
import WeeklyPage from "./routes/Weekly";
import PartnersPage from "./routes/Partners";
import PartnerPage from "./routes/Partner";
import HomeAppPage from "./routes/app/Home";
import { Layout } from "./components/layout/app/Layout";
import ProposalAppPage from "./routes/app/Proposal";
import ProfileAppPage from "./routes/app/Profile";

import { QueryClient, QueryClientProvider } from "react-query";
import { WalletProvider } from "./context/useWalletContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/weekly",
    element: <WeeklyPage />,
  },
  {
    path: "/app",
    element: <Layout />,
    children: [
      {
        path: "goals",
        element: <HomeAppPage />,
      },
      {
        path: "",
        element: <HomeAppPage />,
      },
      {
        path: "proposal/:id",
        element: <ProposalAppPage />,
      },
      {
        path: "profile",
        element: <ProfileAppPage />,
      },
    ],
  },
  {
    path: "/partners",
    element: <PartnersPage />,
  },
  {
    path: "/partner/:id",
    element: <PartnerPage />,
  },
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <WalletProvider>
        <RouterProvider router={router} />
        <ToastContainer
          theme="dark"
          style={{
            borderRadius: "16px",
          }}
          toastClassName="bg-blue-dark#1 rounded-lg"
        />
      </WalletProvider>
    </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

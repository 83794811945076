import Footer from "../Footer";
import Header from "./Header";

export const Layout = ({ children }: { children: any }) => {
  return (
    <main className="w-full h-fit min-h-screen bg-[#08142C] layout__bg relative">
      <section className="max-w-screen-3xl m-auto font-tommy">
        <Header />
        <main className="pb-28">{children}</main>
        <Footer />
      </section>
    </main>
  );
};

interface Props {
  size: number;
}

const Website = ({ size }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size - 1} viewBox="0 0 25 24" fill="none">
      <path
        d="M16.7487 14C16.8287 13.34 16.8887 12.68 16.8887 12C16.8887 11.32 16.8287 10.66 16.7487 10H20.1287C20.2887 10.64 20.3887 11.31 20.3887 12C20.3887 12.69 20.2887 13.36 20.1287 14M14.9787 19.56C15.5787 18.45 16.0387 17.25 16.3587 16H19.3087C18.3398 17.6683 16.8028 18.932 14.9787 19.56ZM14.7287 14H10.0487C9.94867 13.34 9.88867 12.68 9.88867 12C9.88867 11.32 9.94867 10.65 10.0487 10H14.7287C14.8187 10.65 14.8887 11.32 14.8887 12C14.8887 12.68 14.8187 13.34 14.7287 14ZM12.3887 19.96C11.5587 18.76 10.8887 17.43 10.4787 16H14.2987C13.8887 17.43 13.2187 18.76 12.3887 19.96ZM8.38867 8H5.46867C6.42753 6.32721 7.96347 5.06149 9.78867 4.44C9.18867 5.55 8.73867 6.75 8.38867 8ZM5.46867 16H8.38867C8.73867 17.25 9.18867 18.45 9.78867 19.56C7.9673 18.9317 6.43352 17.6677 5.46867 16ZM4.64867 14C4.48867 13.36 4.38867 12.69 4.38867 12C4.38867 11.31 4.48867 10.64 4.64867 10H8.02867C7.94867 10.66 7.88867 11.32 7.88867 12C7.88867 12.68 7.94867 13.34 8.02867 14M12.3887 4.03C13.2187 5.23 13.8887 6.57 14.2987 8H10.4787C10.8887 6.57 11.5587 5.23 12.3887 4.03ZM19.3087 8H16.3587C16.0457 6.76146 15.5822 5.5659 14.9787 4.44C16.8187 5.07 18.3487 6.34 19.3087 8ZM12.3887 2C6.85867 2 2.38867 6.5 2.38867 12C2.38867 14.6522 3.44224 17.1957 5.3176 19.0711C6.24619 19.9997 7.34858 20.7362 8.56184 21.2388C9.77509 21.7413 11.0755 22 12.3887 22C15.0408 22 17.5844 20.9464 19.4597 19.0711C21.3351 17.1957 22.3887 14.6522 22.3887 12C22.3887 10.6868 22.13 9.38642 21.6275 8.17317C21.1249 6.95991 20.3883 5.85752 19.4597 4.92893C18.5312 4.00035 17.4288 3.26375 16.2155 2.7612C15.0023 2.25866 13.7019 2 12.3887 2Z"
        fill="white"
      />
    </svg>
  );
};

export default Website;

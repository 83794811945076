import React from "react";
import { ProposalDto } from "@justapes/api-types";
import Information from "../../../assets/Information";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import AppSection from "../../common/section/appSection";
import ProposalComments from "./ProposalComments";
import classNames from "classnames";
import { useEffect } from "react";
import { useWalletContext } from "../../../context/useWalletContext";
import ProposalChart from "./ProposalChart";

const ProposalDesc = ({
  handleOpenModal,
  proposal,
  isActive,
  votePower,
}: {
  handleOpenModal: () => void;
  proposal: ProposalDto;
  votePower: number;
  isActive: boolean;
}) => {
  const [tooltipVote, setTooltipVote] = React.useState(false);
  return (
    <AppSection>
      <div className="flex justify-between lg:flex-row flex-col">
        <div className="lg:w-3/5 lg:flex lg:flex-col order-2 lg:order-1 mt-4 lg:mt-0">
          <div>
            <div className="text-white text-xl font-bold">Description</div>
            <div
              className="text-grey-default text-base font-medium leading-normal mt-4"
              dangerouslySetInnerHTML={{ __html: proposal.description }}
            ></div>
          </div>
          <div className="text-white text-xl font-bold mt-8">Discussion</div>
          <div className="h-8" />
          <Discord url={proposal.discourseUrl} />
          <div className="h-8" />
          <div className="text-white text-xl font-bold">Timeline</div>
          <ProposalChart id={proposal.proposalId.toString()} />
          <div className="h-8" />
          <div>
            <div className="text-white text-xl font-bold">Comments</div>
            <ProposalComments proposal={proposal} />
          </div>
        </div>
        <div className="flex flex-col gap-2 lg:w-2/6 order-1 lg:order-2">
          <LittleCard
            title={
              <div className="flex gap-2 relative">
                Your Voting Power{" "}
                <Information
                  size={24}
                  onMouseOver={() => setTooltipVote(true)}
                  onMouseLeave={() => setTooltipVote(false)}
                />
                <div
                  id="tooltip-default"
                  role="tooltip"
                  className={classNames(
                    {
                      "opacity-0": tooltipVote === false,
                    },
                    "-top-10 -right-10 absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm "
                  )}
                >
                  Tooltip content
                </div>
              </div>
            }
            value={
              <div>
                <span
                  className={classNames(
                    {
                      "text-white": votePower > 0,
                      "text-rose-500": votePower === 0,
                    },
                    "text-sm font-bold"
                  )}
                >
                  {votePower}{" "}
                </span>
                <span className="text-slate-400 text-sm font-bold">APE</span>
              </div>
            }
          />
          <Quorum number={proposal.votes.abstained + proposal.votes.against + proposal.votes.inFavorOf} />
          <Voting
            isVotePower={votePower > 0}
            isActive={isActive}
            yes={proposal.votes.inFavorOf}
            no={proposal.votes.against}
            abstain={proposal.votes.abstained}
            handleOpenModal={handleOpenModal}
          />
        </div>
      </div>
    </AppSection>
  );
};

export default ProposalDesc;

const LittleCard = ({ title, value }: { title: any; value: any }) => (
  <div className="p-6 bg-blue-dark#2 rounded-2xl flex-col justify-center items-start gap-4 inline-flex mt-4 lg:mt-0 ">
    <div className="self-stretch justify-between items-center inline-flex">
      <div className="text-slate-400 text-sm font-medium leading-tight">{title}</div>
      <div className="text-white text-sm font-bold">{value}</div>
    </div>
  </div>
);

const Quorum = ({ number }: { number: number }) => {
  const [tooltip, setTooltip] = React.useState(false);
  return (
    <div className="p-6 bg-blue-dark#2 rounded-2xl flex-col justify-center items-start gap-4 inline-flex mt-4 lg:mt-0">
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="text-slate-400 text-sm font-medium leading-tight">
          {" "}
          <div className="flex gap-2 relative">
            Quorum <Information size={24} onMouseLeave={() => setTooltip(false)} onMouseOver={() => setTooltip(true)} />
            <div
              id="tooltip-default"
              role="tooltip"
              className={classNames(
                {
                  "opacity-0 invisible": tooltip === false,
                },
                "-top-16 -right-10 absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm "
              )}
            >
              Tooltip content
            </div>
          </div>
        </div>
        <div className="text-white text-sm font-bold">{number}/200</div>
      </div>
      <div className="w-full bg-primary rounded-full h-3 shadow__button">
        <div className="bg-[#09ECDF] h-3 rounded-full" style={{ width: getPoucentage(number, 200) + "%" }}></div>
      </div>
    </div>
  );
};

const Voting = ({
  yes,
  no,
  abstain,
  handleOpenModal,
  isActive,
  isVotePower,
}: {
  yes: number;
  no: number;
  abstain: number;
  isActive: boolean;
  isVotePower: boolean;
  handleOpenModal: () => void;
}) => {
  const wallet = useWalletContext();
  const walletConnected = wallet.walletConnected;

  useEffect(() => {
    console.log(wallet);
  }, [wallet]);

  return (
    <div className="p-6 bg-blue-dark#2 rounded-2xl flex-col justify-center items-start gap-4 inline-flex mt-4 lg:mt-0 ">
      <div className="Voting text-white text-xl font-bold">Voting</div>
      <div className="w-full gap-8 flex flex-col">
        <div className="gap-2 flex flex-col">
          <div className="self-stretch justify-between items-center inline-flex w-full">
            <div className="text-white text-sm font-medium leading-tight">
              <div className="flex">Yes</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="Votes text-white text-sm font-bold">{yes} votes</div>
              <div className="80 text-slate-400 text-sm font-medium leading-tight">({getPoucentage(yes, 200)}%)</div>
            </div>
          </div>
          <div className="w-full bg-primary rounded-full h-3 shadow__button">
            <div className="bg-[#09ECDF] h-3 rounded-full" style={{ width: getPoucentage(yes, 200) + "%" }}></div>
          </div>
        </div>
        <div className="gap-2 flex flex-col">
          <div className="self-stretch justify-between items-center inline-flex w-full">
            <div className="text-white text-sm font-medium leading-tight">
              <div className="flex">No</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="Votes text-white text-sm font-bold">{no} votes</div>
              <div className="80 text-slate-400 text-sm font-medium leading-tight">({getPoucentage(no, 200)}%)</div>
            </div>
          </div>

          <div className="w-full bg-primary rounded-full h-3 shadow__button">
            <div className="bg-[#09ECDF] h-3 rounded-full" style={{ width: getPoucentage(no, 200) + "%" }}></div>
          </div>
        </div>
        <div className="gap-2 flex flex-col">
          <div className="self-stretch justify-between items-center inline-flex w-full">
            <div className="text-white text-sm font-medium leading-tight">
              <div className="flex">Abstain</div>
            </div>
            <div className="flex items-center gap-2">
              <div className="Votes text-white text-sm font-bold">{abstain} votes</div>
              <div className="80 text-slate-400 text-sm font-medium leading-tight">
                ({getPoucentage(abstain, 200)}%)
              </div>
            </div>
          </div>
          <div className="w-full bg-primary rounded-full h-3 shadow__button">
            <div className="bg-[#09ECDF] h-3 rounded-full" style={{ width: getPoucentage(abstain, 200) + "%" }}></div>
          </div>
        </div>
      </div>
      <PrimaryButton
        disabled={!isActive || !isVotePower}
        onClick={handleOpenModal}
        style={{
          marginTop: "1.5rem",
          width: "100%",
        }}
      >
        Vote
      </PrimaryButton>
      {walletConnected !== "connected" ? (
        <div className="flex justify-center w-full">
          <span className="text-slate-400 text-sm font-medium font-['MADE TOMMY'] leading-tight">
            Please connect your wallet to vote.
          </span>
          {/* <span className="ml-1 text-teal-500 text-sm font-medium font-['MADE TOMMY'] leading-tight">Read more</span> */}
        </div>
      ) : (
        !isVotePower && (
          <div className="flex justify-center w-full">
            <span className="text-slate-400 text-sm font-medium font-['MADE TOMMY'] leading-tight">
              You don't have Voting Power to vote.{" "}
            </span>
            {/* <span className="ml-1 text-teal-500 text-sm font-medium font-['MADE TOMMY'] leading-tight">Read more</span> */}
          </div>
        )
      )}
    </div>
  );
};

const Discord = ({ url }: { url: string }) => (
  <a
    className="p-4 lg:p-8 rounded-2xl border border-blue-950 justify-between items-center gap-5 lg:inline-flex"
    href={url}
    target="_blank"
    rel="noreferrer"
  >
    <div className="flex justify-between lg:hidden">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
          d="M25.6933 7.10738C23.92 6.28071 22 5.68071 20 5.33405C19.9825 5.33348 19.965 5.33678 19.9489 5.34369C19.9328 5.3506 19.9183 5.36096 19.9067 5.37405C19.6667 5.81405 19.3867 6.38738 19.2 6.82738C17.0787 6.50738 14.9213 6.50738 12.8 6.82738C12.6133 6.37405 12.3333 5.81405 12.08 5.37405C12.0667 5.34738 12.0267 5.33405 11.9867 5.33405C9.98666 5.68071 8.07999 6.28071 6.29333 7.10738C6.27999 7.10738 6.26666 7.12071 6.25333 7.13405C2.62666 12.5607 1.62666 17.8407 2.11999 23.0674C2.11999 23.094 2.13333 23.1207 2.15999 23.134C4.55999 24.894 6.86666 25.9607 9.14666 26.6674C9.18666 26.6807 9.22666 26.6674 9.23999 26.6407C9.77333 25.9074 10.2533 25.134 10.6667 24.3207C10.6933 24.2674 10.6667 24.214 10.6133 24.2007C9.85333 23.9074 9.13333 23.5607 8.42666 23.1607C8.37333 23.134 8.37333 23.054 8.41333 23.014C8.55999 22.9074 8.70666 22.7874 8.85333 22.6807C8.87999 22.654 8.91999 22.654 8.94666 22.6674C13.5333 24.7607 18.48 24.7607 23.0133 22.6674C23.04 22.654 23.08 22.654 23.1067 22.6807C23.2533 22.8007 23.4 22.9074 23.5467 23.0274C23.6 23.0674 23.6 23.1474 23.5333 23.174C22.84 23.5874 22.1067 23.9207 21.3467 24.214C21.2933 24.2274 21.28 24.294 21.2933 24.334C21.72 25.1474 22.2 25.9207 22.72 26.654C22.76 26.6674 22.8 26.6807 22.84 26.6674C25.1333 25.9607 27.44 24.894 29.84 23.134C29.8667 23.1207 29.88 23.094 29.88 23.0674C30.4667 17.0274 28.9067 11.7874 25.7467 7.13405C25.7333 7.12071 25.72 7.10738 25.6933 7.10738ZM11.36 19.8807C9.98666 19.8807 8.83999 18.614 8.83999 17.054C8.83999 15.494 9.95999 14.2274 11.36 14.2274C12.7733 14.2274 13.8933 15.5074 13.88 17.054C13.88 18.614 12.76 19.8807 11.36 19.8807ZM20.6533 19.8807C19.28 19.8807 18.1333 18.614 18.1333 17.054C18.1333 15.494 19.2533 14.2274 20.6533 14.2274C22.0667 14.2274 23.1867 15.5074 23.1733 17.054C23.1733 18.614 22.0667 19.8807 20.6533 19.8807Z"
          fill="#748FBE"
        />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path
          d="M9.33337 22.6663L22.6667 9.33301"
          stroke="#748FBE"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.33337 9.33301H22.6667V22.6663"
          stroke="#748FBE"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div className="justify-start items-center gap-5 flex mt-4 lg:mt-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        className="hidden lg:block"
      >
        <path
          d="M25.6933 7.10738C23.92 6.28071 22 5.68071 20 5.33405C19.9825 5.33348 19.965 5.33678 19.9489 5.34369C19.9328 5.3506 19.9183 5.36096 19.9067 5.37405C19.6667 5.81405 19.3867 6.38738 19.2 6.82738C17.0787 6.50738 14.9213 6.50738 12.8 6.82738C12.6133 6.37405 12.3333 5.81405 12.08 5.37405C12.0667 5.34738 12.0267 5.33405 11.9867 5.33405C9.98666 5.68071 8.07999 6.28071 6.29333 7.10738C6.27999 7.10738 6.26666 7.12071 6.25333 7.13405C2.62666 12.5607 1.62666 17.8407 2.11999 23.0674C2.11999 23.094 2.13333 23.1207 2.15999 23.134C4.55999 24.894 6.86666 25.9607 9.14666 26.6674C9.18666 26.6807 9.22666 26.6674 9.23999 26.6407C9.77333 25.9074 10.2533 25.134 10.6667 24.3207C10.6933 24.2674 10.6667 24.214 10.6133 24.2007C9.85333 23.9074 9.13333 23.5607 8.42666 23.1607C8.37333 23.134 8.37333 23.054 8.41333 23.014C8.55999 22.9074 8.70666 22.7874 8.85333 22.6807C8.87999 22.654 8.91999 22.654 8.94666 22.6674C13.5333 24.7607 18.48 24.7607 23.0133 22.6674C23.04 22.654 23.08 22.654 23.1067 22.6807C23.2533 22.8007 23.4 22.9074 23.5467 23.0274C23.6 23.0674 23.6 23.1474 23.5333 23.174C22.84 23.5874 22.1067 23.9207 21.3467 24.214C21.2933 24.2274 21.28 24.294 21.2933 24.334C21.72 25.1474 22.2 25.9207 22.72 26.654C22.76 26.6674 22.8 26.6807 22.84 26.6674C25.1333 25.9607 27.44 24.894 29.84 23.134C29.8667 23.1207 29.88 23.094 29.88 23.0674C30.4667 17.0274 28.9067 11.7874 25.7467 7.13405C25.7333 7.12071 25.72 7.10738 25.6933 7.10738ZM11.36 19.8807C9.98666 19.8807 8.83999 18.614 8.83999 17.054C8.83999 15.494 9.95999 14.2274 11.36 14.2274C12.7733 14.2274 13.8933 15.5074 13.88 17.054C13.88 18.614 12.76 19.8807 11.36 19.8807ZM20.6533 19.8807C19.28 19.8807 18.1333 18.614 18.1333 17.054C18.1333 15.494 19.2533 14.2274 20.6533 14.2274C22.0667 14.2274 23.1867 15.5074 23.1733 17.054C23.1733 18.614 22.0667 19.8807 20.6533 19.8807Z"
          fill="#748FBE"
        />
      </svg>
      <div className="flex-col justify-start items-start gap-1.5 inline-flex">
        <div className="text-white text-base font-bold">Would you like to talk about the proposal?</div>
        <div className="text-slate-400 text-sm font-medium leading-tight">
          Join our discourse and engage in the discussion.
        </div>
      </div>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className="hidden lg:block"
    >
      <path
        d="M9.33337 22.6663L22.6667 9.33301"
        stroke="#748FBE"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.33337 9.33301H22.6667V22.6663"
        stroke="#748FBE"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </a>
);

const getPoucentage = (number: number, total: number) => {
  return Math.round((number / total) * 100);
};

const ArrowLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M19 12H5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 19L5 12L12 5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default ArrowLeft;

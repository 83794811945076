const Title = ({ title, desc }: { title: string; desc: string }) => {
  return (
    <div className="flex flex-col mb-24">
      <h1 className="text-center text-white text-6xl lg:text-8xl font-bold">{title}</h1>
      <p className="mt-4 text-center text-white text-base lg:text-lg font-medium leading-normal">{desc}</p>
    </div>
  );
};

export default Title;

/* eslint-disable react-hooks/exhaustive-deps */
import Proposal from "../../../api/proposal";
import CardProposal from "../proposal/Card";
import { useWalletContext } from "../../../context/useWalletContext";
import { useEffect, useState } from "react";
import { ProposalsDto } from "@justapes/api-types";
import { toast } from "react-toastify";
import Loading from "../../common/Loading";

const MyVote = () => {
  const { accounts, walletConnected } = useWalletContext();
  const [proposalsData, setProposalsData] = useState<ProposalsDto>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("walletConnected", walletConnected);
    if (walletConnected === "connected" && accounts.length > 0 && loading === false) {
      setLoading(true);
      console.log("getProposalByVoter");
      Proposal.getProposalByVoter(accounts[0].address)
        .then((data) => {
          console.log("getProposalByVoter data", data);
          setProposalsData(data);
        })
        .catch((err) => {
          toast.error("Error getting proposals");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    return () => setLoading(false);
  }, [walletConnected, accounts.length]);

  return (
    <section className="mt-12 lg:mt-8 relative flex flex-col gap-6">
      <div className="text-white text-xl font-bold">Voted proposals</div>
      <div className="text-grey-default text-lg font-medium leading-normal">
        {proposalsData?.proposals.length} proposals
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="mt-4  grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 ">
          {proposalsData?.proposals.map((proposal, index) => (
            <CardProposal key={index} proposal={proposal} />
          ))}
        </div>
      )}
    </section>
  );
};

export default MyVote;

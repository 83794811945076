import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import SecondaryButton from "../common/buttons/SecondaryButton";
import AppSection from "../common/section/appSection";
import Select from "../common/select/Select";
import Title from "../common/text/Title";
import CardProposal from "./proposal/Card";
import SectionApp from "./SectionApp";

import Pagination from "../common/pagination/Pagination";
import CardSkeletonProposal from "./proposal/CardSkeleton";
import ModalNewProposal from "./newProposal/ModalNewProposal";
import useSearchQuery from "../../hooks/useQuery";

import Proposal from "../../api/proposal";
import { ProposalState } from "@justapes/governance";
import Metadata from "../common/Metadata";

const filters = [
  {
    id: "1",
    value: "All",
  },
  {
    id: "2",
    value: ProposalState.Active,
  },
  {
    id: "3",
    value: ProposalState.Accepted,
  },
  // {
  //   id: "4",
  //   value: ProposalState.Cancelled,
  // },
  // {
  //   id: "5",
  //   value: ProposalState.Declined,
  // },
  // {
  //   id: "6",
  //   value: ProposalState.PendingResolution,
  // },
  // {
  //   id: "7",
  //   value: ProposalState.Vetoed,
  // },
];

const App = () => {
  const [newProposal, setNewProposal] = useState(false); // [1]
  const [searchTitle, setSearchTitle] = useState(""); // [2
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  let query = useSearchQuery();
  const [filter, setFilter] = useState<{ id: string; value: string }>(
    filters.find((item) => item.value.toLowerCase() === query.get("sort")) || filters[1]
  );

  let {
    status,
    data: proposalsData,
    refetch,
  } = useQuery(
    ["proposals", page],
    () => Proposal.getProposals(25, page === 1 ? 0 : (page - 1) * 25, filter?.value, searchTitle),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setFilter(() => filters.find((item) => item.value.toLowerCase() === query.get("sort")) || filters[1]);
    setPage(parseInt(query.get("page") || "1"));
  }, [query]);

  useEffect(() => {
    refetch();
  }, [filter, refetch, searchTitle]);

  const handleFilter = (value: { id: string; value: string }) => {
    navigate({
      pathname: "/app",
      search: "?sort=" + value.value.toLowerCase() + "&page=" + page,
    });
  };

  const handleSearchByTitle = (value: string) => {
    navigate({
      pathname: "/app",
      search: "?sort=" + filter.value.toLowerCase() + "&page=" + page + "&title=" + value,
    });
    setSearchTitle(value);
  };

  const handleOpenNewProposal = () => {
    setNewProposal(!newProposal);
    if (newProposal) document.body.classList.remove("popup-open");
    else document.body.classList.add("popup-open");
  };

  const handlePage = (page: number) => {
    navigate({
      pathname: "/app",
      search: "?sort=" + filter.value.toLowerCase() + "&page=" + page,
    });
  };

  return (
    <SectionApp>
      <Metadata title="Apes Across Worlds" />
      <Title title="ApeVote" desc="A governance platform for the Apes Across World community." />
      <AppSection>
        <h2 className="text-white text-3xl font-bold">Proposals</h2>
        <div className="flex flex-col lg:flex-row justify-between mt-8 relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className="absolute left-3 lg:m-auto top-3 lg:top-0 bottom-0"
          >
            <path
              d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
              stroke="#748FBE"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21 20.9999L16.65 16.6499"
              stroke="#748FBE"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            onChange={(e) => handleSearchByTitle(e.target.value)}
            value={searchTitle}
            type="text"
            placeholder="Search"
            className="w-full lg:w-1/4 rounded-2xl pl-10 p-3  bg-transparent border-[3px] border-blue-dark#3 focus:outline-none focus:ring-2 focus:ring-blue-dark#3 focus:border-transparent"
          />
          <div className="flex flex-col lg:flex-row gap-4 mt-4 lg:mt-0 w-full lg:w-auto">
            <SecondaryButton onClick={() => handleOpenNewProposal()}>Create new Proposal </SecondaryButton>
            <Select value={filters} current={filter.value} handleChange={handleFilter} />
          </div>
        </div>
        <div className="mt-4 lg:mt-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 ">
          {status === "loading" ? (
            Array.from(Array(10).keys()).map((i) => <CardSkeletonProposal key={i} />)
          ) : status === "error" ? (
            <div className="text-white">Error fetching proposals</div>
          ) : proposalsData ? (
            proposalsData.proposals.map((i, key) => <CardProposal key={key} proposal={i} />)
          ) : (
            <div className="text-white">No proposals found</div>
          )}
        </div>
        <div className="flex justify-center mt-8">
          <Pagination
            totalPage={Math.ceil(proposalsData?.total! / 25) || 1}
            currentPage={page}
            onNext={() => handlePage(page + 1)}
            onPrev={() => handlePage(page - 1)}
            onThisPage={handlePage}
          />
        </div>
      </AppSection>
      {newProposal && <ModalNewProposal handleClose={() => handleOpenNewProposal()} />}
    </SectionApp>
  );
};

export default App;

import { useState } from "react";

import CreateProposal from "./CreateProposal";
import Preview from "./Preview";
import useWallet from "../../../hooks/useWallet";
import { getContract, propose } from "@justapes/governance";
import { CreateProposalResponseDto } from "@justapes/api-types";
import { web3FromAddress } from "@polkadot/extension-dapp";
import Proposal from "../../../api/proposal";
import { toast } from "react-toastify";
import { ErrorAPI } from "../../../types/error";

const ModalNewProposal = ({ handleClose }: { handleClose: () => void }) => {
  const { api, accounts } = useWallet();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ title: "", description: "", startDate: "", endDate: "" });
  const [step, setStep] = useState(1);
  const [submitedProposal, setSubmitedProposal] = useState<CreateProposalResponseDto>();

  const handleCreate = async () => {
    try {
      if (!api) return console.log("No api");

      const desc = form.description.replace(/\n/g, "<br />");
      const contract = getContract(api);
      const proposal = await propose(contract, form.title, desc);
      const address = await web3FromAddress(accounts[0].address);
      setLoading(true);
      const signature = await proposal.signAsync(accounts[0].address, { signer: address.signer });

      const response = await Proposal.createProposal(signature.toHex());
      // Check if response is errorAPI or CreateProposalResponseDto
      if (response.hasOwnProperty("code")) {
        const result = response as ErrorAPI;
        if (result.code === 3) {
          toast.error("You have already one proposal in progress.");
          return;
        }
        toast.error((response as ErrorAPI).message);
        return;
      }
      const result = response as CreateProposalResponseDto;

      if (result.response.blockHash) {
        setStep(3);
        setSubmitedProposal(result);
      } else {
        toast.error("Transaction has been rejected.");
      }
    } catch (e) {
      toast.error("Error during proposal creation");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      id="defaultModal"
      aria-hidden="true"
      className="fixed top-0 left-0 right-0 z-50 flex justify-center items-center bg-[#040F25D9] bg-opacity-80 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full max-h-full"
    >
      <div className="relative w-full max-w-[100vw] md:max-w-xl lg:max-w-2xl max-h-full ">
        {step === 1 && <CreateProposal handleClose={handleClose} form={form} setForm={setForm} setStep={setStep} />}
        {step > 1 && (
          <Preview
            form={form}
            handleClose={handleClose}
            setStep={setStep}
            step={step}
            handleConfirm={handleCreate}
            loading={loading}
            submitedProposal={submitedProposal}
          />
        )}
      </div>
    </div>
  );
};

export default ModalNewProposal;

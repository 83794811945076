import CardCore from "./CardCore";
import texts from "../../../../configs/landing.json";

const CoreSummary = () => {
  return (
    <div className="mt-32 w-full flex flex-col lg:flex-row justify-between relative z-10">
      <div className="lg:w-1/3">
        <img
          src="/images/background/planets/planets3.png"
          alt="planet2"
          className="absolute z-0 left-44 top-96 mx-auto w-60 hidden lg:block"
        />
        <div className="text-left flex flex-col lg:ml-14 2xl:ml-28">
          <span className=" text-primary text-lg font-bold font-tommy">Synopsis</span>
          <h2 className="text-white text-5xl font-bold mt-3 font-tommy">Core values</h2>
          <div className="text-slate-400 text-lg font-medium mt-3 font-tommy">What do we really care about?</div>
        </div>
      </div>
      <div className="">
        <div className="grid grid-cols-1 xl:grid-cols-2 3xl:grid-cols-3 gap-4 mt-10  m-auto">
          {texts.summary.cards.map((value, index) => (
            <CardCore key={index} description={value.description} number={value.number} title={value.title} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoreSummary;


const CardSkeletonProposal = () => {
  return (
    <div className="rounded-2xl bg-slate-900 p-6 animate-pulse">
      <div className="flex justify-between">
        <div className="h-8 w-14 rounded-2xl border-[3px] border-blue-dark#3 justify-center items-center gap-2.5 inline-flex">
        
        </div>
        <div className="h-8 w-14 rounded-2xl border-[3px] border-blue-dark#3 justify-center items-center gap-2.5 inline-flex">
       
        </div>
      </div>
      <div className="flex flex-col mt-8">
        <div className="text-white text-lg font-bold h-4 bg-blue-950 rounded-lg"></div>
        <div className="bg-blue-dark#6 text-base font-medium leading-snug mt-4 h-2 rounded-lg"></div>
        <div className="bg-blue-dark#6 text-base font-medium leading-snug mt-4 h-2 rounded-lg"></div>
      </div>
      <div className="mt-8 flex-col">
        <ProgessBar progress={40} />
        <div className="mt-2 flex justify-between">
          <span className="bg-blue-dark#6 text-sm font-bold h-2 rounded-lg"></span>
          <span className="bg-blue-dark#6 text-sm font-bold h-2 rounded-lg"></span>
        </div>
      </div>
    </div>
  );
};

export default CardSkeletonProposal;

const ProgessBar = ({ progress }: { progress: number }) => {
  return (
    <div className="w-full bg-blue-dark#4 rounded-full h-3 shadow__button">
      <div className="bg-blue-dark#6 h-3 rounded-full" style={{ width: progress }}></div>
    </div>
  );
};

import { Link } from "react-router-dom";
import Discord from "../../assets/Discord";
import Twitter from "../../assets/Twitter";
import Website from "../../assets/Website";

const Footer = () => {
  return (
    <footer className="w-full absolute bottom-0">
      <div className="gap-4 lg:gap-0 w-full flex justify-between items-center px-4 lg:px-10 py-6 relative flex-col lg:flex-row">
        <div className="flex items-center gap-4">
          <div className="text-center text-white text-base font-medium leading-snug font-tommy">Powered by</div>
          <img src="/images/logo/EFP_Logo_full_transparent.png" alt="Vercel Logo" className="h-14" />
        </div>
        <div className="order-3 lg:order-none font-tommy z-0 text-center text-white text-base font-medium leading-snug lg:absolute w-full right-0 bottom-0 left-0 top-0 flex justify-center items-center">
          ©2023 Apes Across Worlds
        </div>
        <div className="justify-start items-end gap-6 inline-flex order-2 lg:order-none ">
          <div className="flex items-center gap-6 z-10">
            <Link to="https://discord.gg/justape">
              <Discord size={25} />
            </Link>
            <Link to="https://twitter.com/ApesAW_">
              <Twitter size={25} />
            </Link>
            <Link to="https://bit.ly/justapestudios">
              <Website size={25} />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const setLocalStorage = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

const getLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

const removeLocalStorage = (key: string) => {
  return localStorage.removeItem(key);
};

export { setLocalStorage, getLocalStorage, removeLocalStorage };

import React, { useEffect, useState } from "react";
import { useWalletContext } from "../../../context/useWalletContext";
import classNames from "classnames";

const TopSection = () => {
  const [walletName, setWalletName] = useState("");
  const { accounts } = useWalletContext();
  useEffect(() => {
    if (accounts[0]) {
      setWalletName(accounts[0].address.slice(0, 6) + "..." + accounts[0].address.slice(-4));
    }
  }, [accounts]);
  return (
    <div className="w-full px-4 lg:px-24 py-12 bg-blue-dark#1 rounded-3xl flex-col justify-start items-start gap-2.5 inline-flex">
      <div className="self-stretch justify-center lg:justify-between items-center lg:gap-96 inline-flex">
        <div className="justify-start items-center gap-10 flex lg:flex-row flex-col w-full">
          <img
            className="w-48 h-48 rounded-full border-4 border-cyan-900"
            src="https://via.placeholder.com/200x200"
            alt="topImg"
          />
          <div className="flex-col items-start gap-4 inline-flex w-full">
            <div className="text-center text-white text-5xl font-bold flex flex-col sm:flex-row justify-between w-full">
              <div>{walletName}</div>
              <div
                className={classNames(
                  `text-base mt-2 w-fit m-auto sm:m-0 cursor-pointer px-3 py-3 bg-blue-dark#1 rounded-2xl border-3 border-blue-dark#3 hover:border-grey-default justify-start items-center gap-2.5 inline-flex h-full`
                )}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M14.1666 2.5009C14.3855 2.28203 14.6453 2.10842 14.9313 1.98996C15.2173 1.87151 15.5238 1.81055 15.8333 1.81055C16.1428 1.81055 16.4493 1.87151 16.7353 1.98996C17.0213 2.10842 17.2811 2.28203 17.5 2.5009C17.7188 2.71977 17.8924 2.97961 18.0109 3.26558C18.1294 3.55154 18.1903 3.85804 18.1903 4.16757C18.1903 4.4771 18.1294 4.7836 18.0109 5.06956C17.8924 5.35553 17.7188 5.61537 17.5 5.83424L6.24996 17.0842L1.66663 18.3342L2.91663 13.7509L14.1666 2.5009Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>{" "}
                Edit
              </div>
            </div>
            <div className="justify-start items-center gap-2 inline-flex">
              <div className="text-slate-400 text-lg font-medium leading-normal">My Voting Power:</div>
              <div className="text-white text-lg font-bold">6 Votes</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSection;

import React, { useEffect } from "react";

import TweeterSection from "../common/slide/TweeterSection";
import Join from "./Join";
import WeeklyBoard from "./WeeklyBoard";

const Weekly = () => {
  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="px-4 xl:px-10 py-6 mb-12">
      <div className="p-4 xl:p-8 m-auto text-center">
        <span className="text-center text-primary text-lg font-bold font-tommy">Community</span>
        <h2 className="text-center text-white text-5xl font-bold mt-3 font-tommy">What’s new this week?</h2>
        <div className="text-center text-slate-400 text-lg font-medium mt-3 font-tommy">
          See what's happening in our community this week.
        </div>
      </div>
      <div className="w-full bg-blue-dark#6 rounded-[60px] flex flex-col pb-8 mt-16">
        <div className="p-4 xl:p-8 m-auto text-center">
          <span className="text-center text-primary text-lg font-bold font-tommy">Posts</span>
          <h2 className="text-center text-white text-3xl lg:text-5xl font-bold mt-3 font-tommy">Featured Board</h2>
        </div>
        <TweeterSection />
        
      </div>
      <WeeklyBoard />
      <Join />
    </section>
  );
};

export default Weekly;

const achievements = [
  {
    ico: "https://via.placeholder.com/56x57",
    title: "Top Voter",
    description: "Lorem ipsum dolor sit amet consectetur.",
  },
  {
    ico: "https://via.placeholder.com/56x57",
    title: "Top Voter",
    description: "Lorem ipsum dolor sit amet consectetur.",
  },
  {
    ico: "https://via.placeholder.com/56x57",
    title: "Top Voter",
    description: "Lorem ipsum dolor sit amet consectetur.",
  },
];

const Achievements = () => {
  return (
    <section className="relative">
      <div className="text-white text-xl font-bold">Achievements</div>
      <div className="mt-8 p-6 rounded-2xl border-2 border-blue-dark#3 flex-col justify-start items-start gap-8 inline-flex w-full">
        <div className="self-stretch flex-col justify-start items-start gap-6 flex">
          {achievements.map((achievement, index) => (
            <div className="self-stretch justify-start items-center gap-4 inline-flex" key={index}>
              <img className="w-14 h-14" src={achievement.ico} alt="ico" />
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                <div className="self-stretch text-white text-lg font-bold">{achievement.title}</div>
                <div className="self-stretch text-slate-400 text-sm font-medium leading-tight">
                  {achievement.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Achievements;

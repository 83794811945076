import Discord from "../../assets/Discord";
import Twitter from "../../assets/Twitter";

const Join = () => {
  return (
    <div className="mt-32 w-full flex flex-col lg:flex-row justify-evenly">
      <div className="lg:w-1/3">
        <div className="text-left flex flex-col ml-14 2xl:ml-28">
          <span className=" text-primary text-lg font-bold font-tommy">Community</span>
          <h2 className="text-white text-5xl font-bold mt-3 font-tommy">Join our community </h2>
          <div className="text-slate-400 text-lg font-medium mt-3 font-tommy">
            Keep an eye on all the latest developments with AAW through our socials.
          </div>
        </div>
      </div>
      <div className="lg:w-2/5">
        <div className="grid grid-cols-1 gap-4 mt-10  m-auto">
          <Card who={"twitter"} />
          <Card who={"telegram"} colored />
          <Card who={"discord"} />
        </div>
      </div>
    </div>
  );
};

export default Join;

const Card = ({ ...props }: { who: "twitter" | "telegram" | "discord"; colored?: boolean }) => {
  let title = "";
  let description = "";
  let image;
  if (props.who === "twitter") {
    title = "X";
    description = "Follow the AAW X account for all the latest announcements.";
    image = <Twitter size={40} />;
  }
  if (props.who === "telegram") {
    title = "Discourse";
    description = "Follow along on discourse for all the latest conversations.";
    image = <Twitter size={40} />;
  }
  if (props.who === "discord") {
    title = "Discord";
    description = "Join discord to engage and interact with the community.";
    image = <Discord size={40} />;
  }
  const styled = props.colored ? " bg-primary" : " bg-blue-dark#6";
  const styledText = props.colored ? " text-white" : " text-grey-default";
  return (
    <div className={"rounded-[40px] flex flex-col p-10 relative" + styled}>
      <div className="absolute top-10 right-10">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path
            d="M11.6667 28.3337L28.3333 11.667"
            stroke={props.colored ? "#FFFFFF" : "#748FBE"}
            strokeWidth="3.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.6667 11.667H28.3333V28.3337"
            stroke={props.colored ? "#FFFFFF" : "#748FBE"}
            strokeWidth="3.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="w-full flex gap-8">
        <div className="items-center flex justify-center">{image}</div>
        <div className="flex flex-col w-2/3">
          <div className="text-white text-3xl font-bold font-tommy">{title}</div>
          <div className={"text-base font-medium font-tommy leading-relaxed mt-4" + styledText}>{description}</div>
        </div>
      </div>
    </div>
  );
};

import CardSummary from "./Card";
import CoreSummary from "./core/CoreSummary";
import texts from "../../../configs/landing.json";

const data = [
  {
    title: texts.project.cardA.titleTwo,
    description: texts.project.cardA.desc,
    section: texts.project.cardA.title,
    image: "images/background/island1.png",
  },
  {
    title: texts.project.cardC.titleTwo,
    description: texts.project.cardC.desc,
    section: texts.project.cardC.title,
    image: "images/background/island2.png",
  },
  {
    image: "images/background/island3.png",
    title: texts.project.cardB.titleTwo,
    description: texts.project.cardB.desc,
    section: texts.project.cardB.title,
  },
];

const Summary = () => {
  return (
    <section className="mt-12 lg:mt-32 w-full px-4 xl:px-10 py-6 relative ">
      <img
        src="/images/background/planets/planets2.png"
        alt="planet2"
        className="absolute z-0 right-0 top-12 w-60 hidden lg:block"
      />
      <img
        src="/images/background/planets/planets10.png"
        alt="planet2"
        className="absolute z-0 left-0 bottom-0 w-60 hidden lg:block"
      />

      <div className="p-8 m-auto text-center">
        <span className="text-center text-primary text-lg font-bold font-tommy">Objectives</span>
        <h2 className="text-center text-white text-5xl font-bold mt-3 font-tommy">About AWW</h2>
        <div className="text-center text-slate-400 text-lg font-medium mt-3 font-tommy">
        The ultimate goal is to establish Apes Across Worlds as a premier destination for builders, visionaries and contributors to come together and shape the future of Web3.
        </div>
      </div>
      <div className="flex gap-4 justify-center w-full flex-col lg:flex-row z-10 relative">
        <img
          src="/images/background/planets/planets8.png"
          alt="planet8"
          className="absolute z-0 left-0 -bottom-24 hidden lg:block"
        />
        {data.map((item, index) => (
          <CardSummary
            index={index}
            key={index}
            title={item.title}
            description={item.description}
            image={item.image}
            section={item.section}
          />
        ))}
      </div>
      <CoreSummary />
    </section>
  );
};

export default Summary;

import classNames from "classnames";
import React, { useState } from "react";

interface ListProps {
  value: {
    id: string;
    value: string;
  }[];
  current: string;
  handleChange: (value: any) => void;
}
const Select = ({ value, current, handleChange }: ListProps) => {
  const [open, setOpen] = useState(false);
  const size = 48 * value.length;

  // detect click outside
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (event.target.closest(".select__aaw") === null) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <div className="relative select__aaw cursor-pointer ">
      <div className=" flex-col justify-start items-start gap-2 inline-flex h-full w-full">
        <div
          onClick={() => setOpen(!open)}
          className={classNames(
            {
              "border-blue-dark#3": !open,
              "border-primary": open,
            },
            "w-full px-4 p-3  min-w-[210px] rounded-2xl border-[3px] justify-between items-center gap-2.5 inline-flex h-full hover:border-grey-default"
          )}
        >
          <div className="text-white text-base font-medium leading-snug">{current}</div>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6 9L12 15L18 9" stroke="#748FBE" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      </div>
      <div
        className={classNames(
          "absolute top-17 left-0 w-full rounded-2xl shadow-2xl flex flex-col  bg-blue-dark#1 z-10 overflow-hidden transition-all duration-300 ease-in-out"
        )}
        style={{ height: open ? size : 0, border: open ? "3px solid #223C6D" : "3px solid transparent" }}
      >
        {value.map((item) => (
          <div
            key={item.id}
            className="px-4 py-3 cursor-pointer justify-between flex hover:text-primary"
            onClick={() => handleChange(item)}
          >
            <span>{item.value}</span>
            {item.value === current && <Selected />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Select;

const Selected = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M16.6668 5L7.50016 14.1667L3.3335 10"
      stroke="#03B3A9"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

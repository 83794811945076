import { Link } from "react-router-dom";

const Notification = () => {
  return (
    <div className="select-none text-white flex-col absolute left-0 lg:left-auto right-0 top-24 lg:top-14 cursor-pointer py-2.5 bg-blue-dark#1 rounded-2xl border-3 border-blue-dark#3  justify-start gap-2.5 inline-flex">
      <div className="text-white text-xl font-bold px-4 ">Notifications</div>
      <div className="f max-h-96 overflow-auto">
        <div className="px-4 flex flex-col gap-3">
          <NotificationItem desc={"Lorem ipsum dolor sit amet consectetur."} to="/app/proposal/1" />
          <NotificationItem desc={"Lorem ipsum dolor sit amet consectetur."} to="/app/proposal/1" />
          <NotificationItem desc={"Lorem ipsum dolor sit amet consectetur."} to="/app/proposal/1" />
          <NotificationItem desc={"Lorem ipsum dolor sit amet consectetur."} to="/app/proposal/1" />
          <NotificationItem desc={"Lorem ipsum dolor sit amet consectetur."} to="/app/proposal/1" />
          <NotificationItem desc={"Lorem ipsum dolor sit amet consectetur."} to="/app/proposal/1" />
          <NotificationItem desc={"Lorem ipsum dolor sit amet consectetur."} to="/app/proposal/1" />
        </div>
      </div>
    </div>
  );
};

export default Notification;

const NotificationItem = ({ desc, to }: { desc: string; to: string }) => {
  return (
    <Link
      to={to}
      className="w-full p-4 bg-blue-950 rounded-xl flex-col justify-start items-start gap-1 inline-flex lg:w-max"
    >
      <div className="self-stretch justify-start items-center gap-4 inline-flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
            stroke="#03B3A9"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div className="grow shrink basis-0  justify-start items-start gap-1 inline-flex flex-col">
          <div className="self-stretch text-white text-base font-bold">New proposal is live!</div>
          <div className="self-stretch text-grey-default text-sm font-medium leading-tight">{desc}</div>
        </div>
      </div>
    </Link>
  );
};

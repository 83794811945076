import { Link } from "react-router-dom";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import { useState, useEffect } from "react";
import Notification from "../../app/notification/Notification";
import classNames from "classnames";
import LinkToIco from "../../../assets/LinkToIco";
import Loading from "../../common/Loading";
import { useWalletContext } from "../../../context/useWalletContext";

const Header = () => {
  const { loadAccountsFromExtensions, disconnect, accounts, walletConnected } = useWalletContext();
  const [accountOpen, setAccountOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [sizeOfAccount, setSizeOfAccount] = useState(0);
  const [walletName, setWalletName] = useState("");

  useEffect(() => {
    const size = document.getElementsByClassName("account__head")[0]?.clientWidth;
    setSizeOfAccount(size);

    window.addEventListener("resize", () => {
      const size = document.getElementsByClassName("account__head")[0]?.clientWidth;
      setSizeOfAccount(size);
    });
    return () =>
      window.removeEventListener("resize", () => {
        const size = document.getElementsByClassName("account__head")[0]?.clientWidth;
        setSizeOfAccount(size);
      });
  }, []);

  useEffect(() => {
    if (accounts[0]) {
      setWalletName(accounts[0].address.slice(0, 6) + "..." + accounts[0].address.slice(-4));
    }
  }, [accounts]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (event.target.closest(".account__head") === null) {
        setAccountOpen(false);
      }
      if (event.target.closest(".notification__head") === null) {
        // [1
        setNotificationOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <header className="select-none w-full flex justify-between items-center px-4 lg:px-10 py-6 z-20 relative">
      <Link to="/">
        <img src="/images/logo/logo_head.png" alt="logo" className="w-24" />
      </Link>
      <div className="flex items-center gap-6">
        {walletConnected === "connected" ? (
          <div className="flex items-center gap-2 lg:relative">
            <div
              onClick={() => setNotificationOpen(!notificationOpen)}
              className={classNames(
                { "border-grey-default": notificationOpen },

                `notification__head lg:relative cursor-pointer px-4 py-3.5 bg-blue-dark#1 rounded-2xl border-3 border-blue-dark#3 hover:border-grey-default justify-start items-center gap-2.5 inline-flex h-full`
              )}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {notificationOpen && <Notification />}
            </div>
            <div
              onClick={() => setAccountOpen(!accountOpen)}
              className="relative cursor-pointer px-4 py-2.5 bg-blue-dark#1 rounded-2xl border-3 border-blue-dark#3 hover:border-grey-default justify-start items-center gap-2.5 inline-flex account__head"
            >
              <img className="w-8 h-8 rounded-full" src="https://via.placeholder.com/32x32" alt="profil" />
              <div className="text-white text-base font-bold">{walletName}</div>
            </div>

            {accountOpen && (
              <div
                style={{ width: sizeOfAccount }}
                className="min-w-[177px] absolute top-[5.5em]  lg:top-16 right-4 lg:right-0 p-4 bg-slate-900 rounded-2xl border border-cyan-900 flex-col justify-start items-start gap-4 inline-flex"
              >
                <Link to="profile" className="text-grey-default text-base font-medium leading-snug">
                  My profile
                </Link>
                <div className="h-0.5 bg-blue-dark#3 w-full" />
                <div className="self-stretch h-24 flex-col justify-start items-start gap-3 flex">
                  <Link to={"/docs"} className="self-stretch justify-between items-center gap-3 inline-flex">
                    <div className="text-grey-default text-base font-medium leading-snug">Docs</div>
                    <LinkToIco />
                  </Link>
                  <Link to="/partners" className="self-stretch justify-between items-center gap-3 inline-flex">
                    <div className="text-grey-default text-base font-medium leading-snug">Partners</div>
                    <LinkToIco />
                  </Link>
                  <Link to="discussion" className="self-stretch justify-between items-center gap-3 inline-flex">
                    <div className="text-grey-default text-base font-medium leading-snug">Discussion</div>
                    <LinkToIco />
                  </Link>
                </div>
                <div className="h-0.5 bg-blue-dark#3 w-full" />
                <div className="justify-start items-center gap-2 inline-flex cursor-pointer" onClick={disconnect}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6"
                      stroke="#EF5A5C"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.6667 11.3327L14 7.99935L10.6667 4.66602"
                      stroke="#EF5A5C"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 8H6"
                      stroke="#EF5A5C"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="text-rose-500 text-base font-medium leading-snug">Disconnect</div>
                </div>
              </div>
            )}
          </div>
        ) : walletConnected === "pending" ? (
          <Loading />
        ) : (
          <PrimaryButton
            onClick={() => loadAccountsFromExtensions()}
            style={{
              width: "fit-content",
              padding: "1.0rem 2.5rem",
            }}
          >
            Connect Wallet
          </PrimaryButton>
        )}
      </div>
    </header>
  );
};

export default Header;

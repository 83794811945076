import { useParams } from "react-router-dom";
import ProposalApp from "../../components/app/proposal/Proposal";

const ProposalAppPage = () => {
  const { id } = useParams();
  if (!id) throw new Error("No id in URL");
  return <ProposalApp id={id} />;
};

export default ProposalAppPage;

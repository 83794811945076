import classNames from "classnames";

interface IPrimaryButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  size?: "tiny" | "small" | "medium" | "large" | "default";
  disabled?: boolean;
  style?: React.CSSProperties;
}

const SecondaryButton = ({ children, onClick, size, disabled, style }: IPrimaryButtonProps) => {
  let buttonDefaultSize = "";

  switch (size) {
    case "tiny":
      buttonDefaultSize = "text-small px-6 py-2";
      break;
    case "small":
      buttonDefaultSize = "text-text#3 px-8 py-4";
      break;
    case "medium":
      buttonDefaultSize = "text-text#3 px-9 py-5";
      break;
    case "large":
      buttonDefaultSize = "text-text#2 px-10 py-5";
      break;
    default:
      buttonDefaultSize = "3xl:text-text#2 3xl:px-10 3xl:py-5 2xl:text-text#2 2xl:px-10 2xl:py-5 text-text#3 px-8 py-4 w-full lg:w-auto";
      break;
  }

  return (
    <button
      className={classNames(
        {
          "cursor-not-allowed flex justify-center items-center": disabled,
          "bg-blue-dark#3 hover:bg-blue-dark#2 text-white": !disabled,
          "bg-blue-dark#5 text-blue-dark#3": disabled,
        },
        "font-bold py-2 px-4 rounded-2xl shadow__button active:shadow-none font-tommy",
        buttonDefaultSize
      )}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default SecondaryButton;

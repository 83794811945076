import Metadata from "../../common/Metadata";
import BottomSection from "./BottomSection";
import TopSection from "./TopSection";

const Profile = () => {
  return (
    <section className="mt-12 lg:mt-8 relative">
      <Metadata title="AAW | My Profile" />
      <TopSection />
      <BottomSection />
    </section>
  );
};

export default Profile;

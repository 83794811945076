const Skeleton = ({ colored }: { colored: boolean }) => {
  const color = colored ? " bg-blue-dark#3" : " bg-blue-dark#6";
  const color2 = colored ? " bg-teal-500" : " bg-blue-dark#5";
  return (
    <div className="h-48 p-4 bg-slate-900 rounded-2xl flex-col justify-start items-start gap-4 inline-flex">
      <div className="self-stretch justify-between items-center gap-3 inline-flex w-full">
        <div className={"w-3/6 px-3 py-3.5 rounded-xl" + color2} />
        <div className="w-3/6 px-3 py-3.5 bg-blue-950 rounded-xl" />
      </div>
      <div className="self-stretch h-24 flex-col justify-start items-start gap-4 flex">
        <div className="self-stretch h-24 flex-col justify-start items-start gap-4 flex">
          <div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
            <div className={"w-2/3 h-3  rounded-3xl" + color2} />
            <div className="justify-start items-start gap-0.5 inline-flex w-full">
              <div className={"w-1/6 h-3  rounded-md" + color} />
              <div className={"w-2/6 h-3  rounded-md" + color} />
              <div className={"w-2/6 h-3  rounded-md" + color} />
              <div className={"w-1/6 h-3  rounded-md" + color} />
              <div className={"w-1/6 h-3  rounded-md" + color} />
            </div>
            <div className="justify-start items-start gap-0.5 inline-flex w-5/6">
              <div className={"w-3/6 h-3  rounded-md" + color} />
              <div className={"w-2/6 h-3  rounded-md" + color} />
              <div className={"w-1/6 h-3  rounded-md" + color} />
            </div>
          </div>
          <div className="self-stretch h-8 flex-col justify-start items-start gap-2 flex">
            <div className={"w-5/6 h-3 rounded-3xl" + color2} />
            <div className="self-stretch justify-between items-center gap-3 inline-flex">
              <div className="justify-start w-full items-start gap-0.5 flex">
                <div className={"w-3/6 h-3  rounded-md" + color} />
                <div className={"w-2/6 h-3  rounded-md" + color} />
                <div className={"w-3/6 h-3  rounded-md" + color} />
              </div>
              <div className="w-4/6 h-3 bg-blue-950 rounded-3xl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;

import { Link } from "react-router-dom";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import Skeleton from "./Skeleton";
import texts from "../../../configs/landing.json";

const ApeVote = () => {
  return (
    <section className="mt-12 lg:mt-32 px-4 xl:px-10 relative ">
      <img src="/images/background/planets/planets5.png" alt="planet5" className="absolute z-0 left-16 -top-32" />
      <div className="w-full bg-blue-dark#6 rounded-3xl flex flex-col lg:flex-row justify-between overflow-hidden 2xl:gap-28 z-10 relative">
        <div className="lg:w-1/2 p-8 2xl:p-16">
          {" "}
          <div className="lg:p-8 m-auto ">
            <span className="text-primary text-lg font-bold font-tommy"> {texts.gouvernance.title}</span>
            <h2 className="text-white text-5xl font-bold mt-3 font-tommy"> {texts.gouvernance.titleA}</h2>
            <div className="text-slate-400 text-lg font-medium mt-8 font-tommy">{texts.gouvernance.desc}</div>
            <div className="mt-24">
              <Link to="/app">
                <PrimaryButton size="small" style={{ paddingLeft: "1rem", paddingRight: "1rem", borderRadius: "12px" }}>
                  Launch ApeVote
                </PrimaryButton>
              </Link>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 relative">
          <div className="lg:absolute w-[260%] lg:w-[110%] h-[110%] -top-24 -right-10 -ml-16 lg:ml-0 grid grid-cols-4 md:grid-cols-6 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-3 gap-4">
            {Array.from({ length: 12 }).map((_, i) => {
              const randomBoolean = Math.random() >= 0.5;
              return <Skeleton colored={randomBoolean} key={i} />;
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApeVote;

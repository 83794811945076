interface Props {
  size: number;
  onMouseOver: () => void;
  onMouseLeave: () => void;
}

const Information = ({ size, onMouseOver, onMouseLeave }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <g clip-path="url(#clip0_572_2495)">
        <path
          d="M7.9998 14.6654C11.6817 14.6654 14.6665 11.6806 14.6665 7.9987C14.6665 4.3168 11.6817 1.33203 7.9998 1.33203C4.3179 1.33203 1.33313 4.3168 1.33313 7.9987C1.33313 11.6806 4.3179 14.6654 7.9998 14.6654Z"
          stroke="#748FBE"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M7.99994 10.6667V8" stroke="#748FBE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8 5.33203H8.00667" stroke="#748FBE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_572_2495">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Information;

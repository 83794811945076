import { ProposalDto } from "@justapes/api-types";

const ProposalComments = ({ proposal }: { proposal: ProposalDto }) => {
  return (
    <div className="p-4 lg:p-8 rounded-2xl border-3 border-blue-dark#2 flex-col justify-start items-start gap-6 inline-flex mt-4 w-full">
      <div className="text-grey-default text-lg font-medium leading-normal">
        {proposal.votes.receipts.length} Comments
      </div>
      <div className="self-stretch flex-col justify-start items-start gap-2 flex">
        {proposal.votes.receipts.map((receipt) => {
          const name = receipt.voter.id.substring(0, 6) + "..." + receipt.voter.id.substring(receipt.voter.id.length - 4);
          return (
            <Comment
              key={receipt.block}
              from={name}
              date={"5 days ago"}
              value={receipt.reason || ""}
              picture=""
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProposalComments;

const Comment = ({ from, date, picture, value }: { from: string; date: string; picture: string; value: string }) => (
  <div className="self-stretch p-4 bg-blue-dark#6 rounded-xl flex-col justify-start items-start gap-4 flex">
    <div className="justify-start items-center gap-3 inline-flex">
      <img className="w-12 h-12 rounded-full" src="https://via.placeholder.com/32x32" alt="profil" />
      <div className="flex-col justify-start items-start gap-px inline-flex">
        <div className="text-white text-base font-bold">{from}</div>
        <div className="text-grey-default text-sm font-medium leading-tight">{date}</div>
      </div>
    </div>
    <div className="self-stretch pl-14 justify-start items-center gap-2.5 inline-flex">
      <div className="grow shrink basis-0 text-grey-default text-base font-medium leading-normal">{value}</div>
    </div>
  </div>
);

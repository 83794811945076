import React, { useState } from "react";

import Information from "../../../assets/Information";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import classNames from "classnames";
import { ProposalDto } from "@justapes/api-types";
import { formatLongWallet } from "../../../utils/format";
import { VoteType, castVote, getContract } from "@justapes/governance";
import { web3FromAddress } from "@polkadot/extension-dapp";
import Vote from "../../../api/vote";
import { useWalletContext } from "../../../context/useWalletContext";
import { toast } from "react-toastify";

const ModalVote = ({
  handleClose,
  proposal,
  votePower,
}: {
  handleClose: () => void;
  proposal: ProposalDto;
  votePower: number;
}) => {
  const { api, accounts } = useWalletContext();
  const [loading, setLoading] = useState(false);
  const [nbChar, setNbChar] = useState(0);
  const [form, setForm] = useState({ vote: "", nbVote: 0, description: "" });
  const [tooltip, setTooltip] = useState(false);

  const handleNbChar = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 80) return;
    setNbChar(e.target.value.length);
    setForm({ ...form, description: e.target.value });
  };

  const handleSelectVote = (vote: string) => {
    setForm({ ...form, vote });
  };

  const handleVote = async () => {
    try {
      if (!api) return toast.error("No api found, please connect your wallet");
      const contract = getContract(api);

      const address = await web3FromAddress(accounts[0].address);
      const proposalIdInBigInt = BigInt(proposal.proposalId);
      const vote: VoteType =
        form.vote === "yes" ? VoteType.InFavorOf : form.vote === "no" ? VoteType.Against : VoteType.Abstained;

      const voteSignature = castVote(
        contract,
        proposalIdInBigInt,
        vote,
        form.description.length > 0 ? form.description : undefined
      );
      setLoading(true);
      const signature = await voteSignature.signAsync(accounts[0].address, { signer: address.signer });

      const result = await Vote.addVote(signature.toHex());
      if (result.blockHash) {
        toast.success("Vote successfully added");
      }
    } catch (e) {
      toast.error("Error during vote");
    } finally {
      setLoading(false);
    }
  };

  const handleNbVote = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > votePower) return;
    setForm({ ...form, nbVote: Number(e.target.value) });
  };

  return (
    <div
      id="defaultModal"
      aria-hidden="true"
      className="fixed top-0 left-0 right-0 z-50 flex justify-center items-center bg-[#040F25D9] bg-opacity-80 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full max-h-full"
    >
      <div className="relative w-full max-w-[100vw] md:max-w-xl lg:max-w-2xl max-h-full ">
        <div className="relative rounded-lg border-3 border-blue-dark#6 bg-blue-dark#1 p-4 xl:p-8">
          <div className="flex justify-between items-center">
            <h3 className="text-white text-xl font-bold">Voting</h3>

            <button
              onClick={handleClose}
              type="button"
              className="p-2 rounded-2xl border-3 border-blue-dark#3 flex-col justify-center items-center gap-2.5 inline-flex"
              data-modal-hide="defaultModal"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M18 6L6 18" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6 6L18 18" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="w-full flex justify-center py-4">
            <div className="p-6 bg-blue-950 rounded-2xl flex-col justify-center items-start gap-3 inline-flex w-full">
              <div className="text-white text-lg font-bold">{proposal.title}</div>
              <div className="justify-start items-center gap-3 xl:inline-flex">
                <div className="justify-start items-center gap-2 flex">
                  <div className="text-grey-default text-sm font-medium leading-tight">Proposal:</div>
                  <div className="text-white text-sm font-bold">{proposal.proposalId}</div>
                </div>
                <div className="w-1 h-1 bg-grey-default rounded-full hidden xl:block" />
                <div className="justify-start items-center gap-2 flex">
                  <div className="text-grey-default text-sm font-medium leading-tight">Author:</div>
                  <div className="justify-start items-center gap-2 flex">
                    <img className="w-5 h-5 rounded-full" src="https://via.placeholder.com/20x20" alt="pict" />
                    <div className="text-white text-sm font-bold">{formatLongWallet(proposal.proposer.id)}</div>
                  </div>
                </div>
                {/* <div className="w-1 h-1 bg-grey-default rounded-full hidden lg:block" />
                <div className="justify-start items-center gap-2 flex">
                  <div className="text-grey-default text-sm font-medium leading-tight">Txn:</div>
                  <div className="text-white text-sm font-bold">0xf7...2623</div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="text-grey-default text-sm font-medium leading-tight">Your Voting Power</div>
            <div className="pl-6 pr-2 py-2  rounded-2xl border-3 border-blue-dark#3 justify-end items-center gap-3 inline-flex w-full mt-2">
              <div className="flex justify-between w-2/3 flex-col md:flex-row">
                <div className="grow shrink basis-0  justify-start items-baseline gap-0.5 flex">
                  <input
                    type="number"
                    className="bg-blue-dark#1 text-white text-xl w-fit  block p-2.5 focus:outline-none"
                    placeholder={votePower.toString()}
                    value={form.nbVote}
                    onChange={handleNbVote}
                  />
                  <div className="text-grey-default text-xl font-medium">/{votePower}</div>
                </div>
                <div
                  onClick={() => setForm({ ...form, nbVote: votePower })}
                  className="cursor-pointer w-16 px-4 py-2.5 bg-blue-dark#3 rounded-xl shadow-inner justify-center items-center gap-2.5 flex"
                >
                  <div className="text-center text-white text-sm font-bold">Max</div>
                </div>
              </div>
              <div className="self-stretch px-4 py-3 bg-blue-dark#3 rounded-xl flex-col justify-start items-start gap-1 inline-flex">
                <div className="self-stretch text-grey-default text-xs font-medium leading-none">
                  Voting Power calculation
                </div>
                <div className="self-stretch text-white text-sm font-bold">1 APE = 1 Vote</div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="text-grey-default text-sm font-medium leading-tight">Your Vote</div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-2">
              <button
                onClick={() => handleSelectVote("yes")}
                className={classNames(
                  { "border-primary": form.vote === "yes" },
                  "text-lg font-bold p-6 text-center text-white rounded-2xl border-3 border-blue-dark#3 justify-center items-center"
                )}
              >
                Yes
              </button>
              <button
                onClick={() => handleSelectVote("no")}
                className={classNames(
                  { "border-primary": form.vote === "no" },
                  "text-lg font-bold p-6 text-center text-white rounded-2xl border-3 border-blue-dark#3 justify-center items-center"
                )}
              >
                No
              </button>
              <button
                onClick={() => handleSelectVote("abstain")}
                className={classNames(
                  { "border-primary": form.vote === "abstain" },
                  "text-lg font-bold p-6 text-center text-white rounded-2xl border-3 border-blue-dark#3 justify-center items-center"
                )}
              >
                Abstain
              </button>
            </div>
          </div>
          <div className="mt-4">
            <div className="text-grey-default text-sm font-medium leading-tight flex justify-between">
              <span className="flex gap-2 relative">
                Leave a comment{" "}
                <Information size={8} onMouseLeave={() => setTooltip(false)} onMouseOver={() => setTooltip(true)} />
                <div
                  id="tooltip-default"
                  role="tooltip"
                  className={classNames(
                    {
                      "opacity-0 invisible": tooltip === false,
                    },
                    "-top-10 -right-10 absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm "
                  )}
                >
                  Tooltip content
                </div>
              </span>
              <div className="flex">
                <div className="text-white text-sm font-medium leading-tight">{nbChar}</div>
                <div className="text-slate-400 text-sm font-medium leading-tight">/80</div>
              </div>
            </div>
            <div className="mt-2">
              <textarea
                onChange={handleNbChar}
                className="w-full p-4 rounded-2xl border-3 border-blue-dark#3 bg-blue-dark#1 text-white text-sm font-medium leading-tight outline-none resize-none h-24"
                placeholder="Write your comment about the proposal..."
              />
            </div>
          </div>
          <div className="mt-4 w-full">
            <PrimaryButton
              onClick={handleVote}
              style={{
                width: "100%",
              }}
              loading={loading}
              disabled={form.vote === "" || form.description === ""}
            >
              Vote
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalVote;

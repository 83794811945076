import classname from "classnames";
import React, { useEffect } from "react";

const Apes = () => {
  const [nbOfApes, setNbOfApes] = React.useState(0);

  useEffect(() => {
    const handleResize = () => {
      const rootElement = document.getElementById("root");
      if (rootElement) {
        const multiplier = rootElement.clientWidth > 1024 ? 0.8 : 1;
        const nbOfApes = Math.floor(rootElement.clientWidth / 100) * multiplier;

        setNbOfApes(nbOfApes);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="mt-12 overflow-hidden flex flex-col text-white ml-0 justify-center relative h-[50vh]">
      <div className="absolute w-full h-full z-10" />
      <div className="flex gap-4 w-[110vw] absolute -left-10 lg:-left-20">
        {Array.from({ length: nbOfApes }).map((_, index) => {
          const randomNumber = Math.floor(Math.random() * 20) + 1;
          const randomNumber1 = Math.floor(Math.random() * 20) + 1;

          return (
            <div key={index} className={classname({ "mt-8": index % 2 !== 0 }, "flex flex-col gap-4 relative")}>
              {/* <img src={"/images/home/apes/" + randomNumber + ".png"} alt="apes" className="rounded-2xl" /> */}
              <img
                src={"/images/home/apes/" + randomNumber + ".webp"}
                alt="apes"
                className="rounded-2xl border-3 border-blue-dark#3"
              />
              <div className="relative">
                <div className="absolute bg-gradient-to-t from-[#08142C] to-transparent  w-full h-full rounded-2xl top-0 right-0 m-auto" />
                <img
                   src={"/images/home/apes/" + randomNumber1 + ".webp"}
                  alt="apes"
                  className="rounded-2xl apes__bg shadow-inner border-2 border-blue-dark#3"
                />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Apes;

const Share = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M18.75 8C20.4069 8 21.75 6.65685 21.75 5C21.75 3.34315 20.4069 2 18.75 2C17.0931 2 15.75 3.34315 15.75 5C15.75 6.65685 17.0931 8 18.75 8Z"
      stroke="white"
      stroke-width="2.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.75 15C8.40685 15 9.75 13.6569 9.75 12C9.75 10.3431 8.40685 9 6.75 9C5.09315 9 3.75 10.3431 3.75 12C3.75 13.6569 5.09315 15 6.75 15Z"
      stroke="white"
      stroke-width="2.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.75 22C20.4069 22 21.75 20.6569 21.75 19C21.75 17.3431 20.4069 16 18.75 16C17.0931 16 15.75 17.3431 15.75 19C15.75 20.6569 17.0931 22 18.75 22Z"
      stroke="white"
      stroke-width="2.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.33984 13.5098L16.1698 17.4898"
      stroke="white"
      stroke-width="2.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.1598 6.50977L9.33984 10.4898"
      stroke="white"
      stroke-width="2.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);


export default Share;
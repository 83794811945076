import { RpcResponseDto } from "@justapes/api-types";
import { API_URL } from "../configs";

class Vote {
  static async addVote(transaction: string) {
    const response = await fetch(API_URL + "/vote", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ transaction }),
    });
    return response.json() as Promise<RpcResponseDto>;
  }

  static async getVotePower(address: string, block: number, proposalId: number) {
    const response = await fetch(API_URL + "/proposal/" + proposalId + "/votes/" + address + "?block=" + block, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.json() as Promise<{ votePower: string }>;
  }
}

export default Vote;

import { defaultDescription } from "../../configs";

const Metadata = ({
  title,
  description = defaultDescription,
  image = "/assets/images/logo.png",
  url = window.location.href,
}: {
  title: string;
  description?: string;
  image?: string;
  url?: string;
}) => (
  <>
    <title>{title}</title>
    <meta name="title" content={title} />
    <meta name="description" content={description} />

    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    {image && <meta property="og:image" content={image} />}

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content={url} />
    {image && <meta property="twitter:image" content={image} />}
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
  </>
);


export default Metadata

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import SectionApp from "../SectionApp";
import ModalVote from "../vote/ModalVote";
import ProposalDesc from "./ProposalDesc";
import ProposalStatus from "./ProposalStatus";
import { useQuery } from "react-query";
import Proposal from "../../../api/proposal";
import Loading from "../../common/Loading";
import Vote from "../../../api/vote";
import { useWalletContext } from "../../../context/useWalletContext";
import Metadata from "../../common/Metadata";

const ProposalApp = ({ id }: { id: string }) => {
  const [proposalOpen, setProposalOpen] = useState(false);
  const [votePower, setVotePower] = useState(0);
  const [votePowerLoading, setVotePowerLoading] = useState(false); // [1
  const { accounts } = useWalletContext();

  const { data: proposal, status } = useQuery(["proposal", id], () => Proposal.getProposal(id), {
    enabled: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    async function getVotePower() {
      if (
        !accounts ||
        accounts.length === 0 ||
        !proposal ||
        !proposal?.blockNumber ||
        votePowerLoading ||
        !proposal?.proposalId
      )
        return;
      setVotePowerLoading(true);
      await Vote.getVotePower(accounts[0].address, proposal?.blockNumber, proposal.proposalId)
        .then((res) => {
          setVotePower(Number(res));
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setVotePowerLoading(false);
        });
    }
    getVotePower();
  }, [accounts, proposal]);

  const handleOpenVote = () => {
    if (!accounts || accounts.length === 0) return alert("Please connect your wallet");

    setProposalOpen(!proposalOpen);
    if (proposalOpen) document.body.classList.remove("popup-open");
    else document.body.classList.add("popup-open");
  };

  if (status === "loading")
    return (
      <SectionApp>
        <Metadata title="Loading..."/>
        <Loading />
      </SectionApp>
    );
  if (status === "error" || !proposal)
    return (
      <SectionApp>
         <Metadata title="AAW"/>
        <span className="text-white flex justify-center">Error during fetching the propal</span>
      </SectionApp>
    );

  return (
    <SectionApp>
      <Metadata title={"AAW - " + proposal.title} />
      <ProposalStatus proposal={proposal} />
      <div className="h-8" />
      <ProposalDesc
        handleOpenModal={handleOpenVote}
        proposal={proposal}
        votePower={votePower}
        isActive={proposal.state === "Active"}
      />
      {proposalOpen && <ModalVote handleClose={() => handleOpenVote()} proposal={proposal} votePower={votePower} />}
    </SectionApp>
  );
};

export default ProposalApp;

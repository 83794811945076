import classNames from "classnames";

interface PaginationProps {
  totalPage: number;
  currentPage: number;
  onNext: () => void;
  onPrev: () => void;
  onThisPage: (page: number) => void;
}

const Pagination = ({ totalPage, currentPage, onNext, onPrev, onThisPage }: PaginationProps) => {
  const anotherPage = currentPage === 1 ? false : totalPage === currentPage ? false : true;
  return (
    <div className="justify-start items-end gap-2 inline-flex">
      {totalPage > 1 && (
        <div
          onClick={() => currentPage > 1 && onPrev()}
          className="cursor-pointer w-12 h-12 px-6 py-4 bg-blue-dark#3 shadow__button rounded-full shadow-inner flex-col justify-center items-center gap-2.5 inline-flex"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 20 20"
            fill="none"
            className="rotate-180 cursor-pointer"
          >
            <path
              d="M4.16671 10L15.8334 10"
              stroke="white"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 4.16732L15.8334 10.0007L10 15.834"
              stroke="white"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
      <div onClick={() => onThisPage(1)}>
        <Page current={currentPage === 1} number={1} />
      </div>
      {anotherPage ? (
        <>
          <div className="text-center text-slate-400 text-base font-bold">...</div>
          <div onClick={() => currentPage > 1 && onThisPage(currentPage - 1)}>
            <Page current={false} number={currentPage - 1} />
          </div>
          <Page current={true} number={currentPage} />

          <div onClick={() => currentPage < totalPage && onThisPage(currentPage + 1)}>
            <Page current={false} number={currentPage + 1} />
          </div>
          <div className="text-center text-slate-400 text-base font-bold">...</div>
        </>
      ) : totalPage === 1 ? (
        <></>
      ) : (
        <div className="text-center text-slate-400 text-base font-bold">...</div>
      )}
      {totalPage > 1 && (
        <div onClick={() => onThisPage(totalPage)}>
          <Page current={currentPage === totalPage} number={totalPage} />
        </div>
      )}
      {totalPage > 1 && (
        <div
          onClick={() => currentPage < totalPage && onNext()}
          className="cursor-pointer w-12 h-12 px-6 py-4 bg-blue-dark#3  shadow__button rounded-full shadow-inner flex-col justify-center items-center gap-2.5 inline-flex"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 20 20"
            fill="none"
            className="cursor-pointer"
          >
            <path
              d="M4.16671 10L15.8334 10"
              stroke="white"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 4.16732L15.8334 10.0007L10 15.834"
              stroke="white"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Pagination;

const Page = ({ current, number }: { current: boolean; number: number }) => (
  <div
    className={classNames(
      {
        "border-blue-dark#3": !current,
        "border-primary": current,
      },
      "cursor-pointer w-12 h-12 px-6 py-4 rounded-2xl border-[3px]  flex-col justify-center items-center gap-2.5 inline-flex hover:border-grey-default"
    )}
  >
    <div
      className={classNames(
        {
          "text-blue-dark#3": !current,
          "text-primary": current,
        },
        "text-center text-base font-bold"
      )}
    >
      {number}
    </div>
  </div>
);

import React, { ReactNode, createContext, useContext } from "react";
import useWallet, { InjectedAccountWithMeta, InjectedExtension } from "../hooks/useWallet";
import { ApiPromise } from "@polkadot/api";

interface IwalletContext {
  extensions: InjectedExtension[];
  loadAccountsFromExtensions: () => Promise<void>;
  accounts: InjectedAccountWithMeta[];
  walletConnected: "disconnected" | "pending" | "connected";
  api: ApiPromise | undefined;
  disconnect: () => void;
}

const WalletContext = createContext<IwalletContext | null>(null);

interface WalletProviderProps {
  children: ReactNode;
}

export function WalletProvider({ children }: WalletProviderProps) {
  const wallet = useWallet();

  return <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>;
}

export function useWalletContext() {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error("An error occurred while trying to use the WalletContext");
  }
  return context;
}

import { Link } from "react-router-dom";
import "./card.css";

interface PartnerProps {
  id: string;
  name: string;
  description: string;
  logo: string;
  website: string;
  type: string;
}

const Card = ({ ...props }: PartnerProps) => {
  const { id, description, logo, name } = props;

  return (
    <Link
      to={"/partner/" + id.toLocaleLowerCase()}
      className="h-full relative w-full min-h-[510px] p-8 hover:bg-primary bg-blue-dark#6 rounded-3xl flex-col justify-start items-center gap-6 inline-flex card__partner cursor-pointer"
    >
      <div className="absolute w-full h-full bg-black opacity-40 top-0 rounded-3xl backgroundBlack"></div>

      <div className="absolute top-5 right-5 arrow invisible z-10">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path
            d="M11.6667 28.3337L28.3333 11.667"
            stroke="white"
            strokeWidth="3.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.6667 11.667H28.3333V28.3337"
            stroke="white"
            strokeWidth="3.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="pl-3 pr-3.5 pt-3 pb-3.5 rounded-full border border-cyan-900 justify-center items-center inline-flex z-10">
        <img className="w-36 h-36 opacity-80 rounded-full" src={logo} alt="" />
      </div>
      <div className="self-stretch flex-col justify-start items-center gap-4 flex z-10">
        <div className="self-stretch text-center text-white text-3xl font-bold">{name}</div>
        <div className="self-stretch text-center text-slate-400 text-base font-medium leading-snug">{description}</div>
      </div>
    </Link>
  );
};

export default Card;

import React from "react";
import { Link } from "react-router-dom";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import Discord from "../../../assets/Discord";
import Twitter from "../../../assets/Twitter";
import Website from "../../../assets/Website";
import classNames from "classnames";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  React.useEffect(() => {
    // disable scroll when menu is open
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isMenuOpen]);

  React.useEffect(() => {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    return () => {
      window.removeEventListener("resize", () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
    };
  }, []);

  return (
    <header className="w-full flex justify-between items-center px-10 py-6 relative z-20">
      <Link to="/">
        <img src="/images/logo/logo_head.png" alt="logo" className="w-24" />
      </Link>
      <div className="absolute sm:hidden right-4 top-0 bottom-0 flex justify-center m-auto items-center z-50">
        <div className={classNames({ change: isMenuOpen }, "container")} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
      <div className="items-center gap-6 hidden sm:flex">
        <Link to="https://discord.gg/justape">
          <Discord size={25} />
        </Link>
        <Link to="https://twitter.com/ApesAW_">
          <Twitter size={25} />
        </Link>
        <Link to="https://bit.ly/justapestudios">
          <Website size={25} />
        </Link>
        <Link to="/app">
          <PrimaryButton size="small" style={{ paddingLeft: "1rem", paddingRight: "1rem", borderRadius: "12px" }}>
            Launch ApeVote
          </PrimaryButton>
        </Link>
      </div>
      <div
        className={classNames(
          isMenuOpen ? "header__open" : "h-0",
          "transition-all duration-500 ease-in-out overflow-hidden",
          "z-30 bg-[#08142C] text-white font-semibold inline-flex items-center fixed top-20 right-0 m-auto px-3 flex-col space-y-2 w-screen justify-center"
        )}
      >
        <div className="flex flex-col h-full z-10 mt-2 p-2  w-full justify-between pb-4">
          <div className="flex flex-col z-10 mt-2 p-2  w-full">
            <Link
              to="https://discord.gg/justape"
              className="flex gap-4 w-full justify-between border-b-2 border-blue-dark#2 py-8"
            >
              <div className="flex gap-4">
                <Discord size={25} />
                <span>Discord</span>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7 17L17 7" stroke="#748FBE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 7H17V17" stroke="#748FBE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Link>
            <Link
              to="https://twitter.com/ApesAW_"
              className="flex gap-4 w-full justify-between  border-b-2 border-blue-dark#2 py-8"
            >
              <div className="flex gap-4">
                <Twitter size={25} />
                <span>X</span>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7 17L17 7" stroke="#748FBE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 7H17V17" stroke="#748FBE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Link>
            <Link to="https://bit.ly/justapestudios" className="flex gap-4 w-full justify-between py-8">
              <div className="flex gap-4">
                <Website size={25} />
                <span>Notion</span>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M7 17L17 7" stroke="#748FBE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7 7H17V17" stroke="#748FBE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Link>
          </div>
          <Link to="/app">
            <PrimaryButton style={{ paddingLeft: "1rem", paddingRight: "1rem", borderRadius: "12px" }}>
              Launch ApeVote
            </PrimaryButton>
            </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;

import { Layout } from "../components/layout/landing/Layout";
import Home from "../components/landing/home";
import Apes from "../components/landing/apes/Apes";
import Community from "../components/landing/community/Community";
import Summary from "../components/landing/summary/Summary";
import ApeVote from "../components/landing/apeVote/ApeVote";
import Partners from "../components/landing/partners/Partners";

const HomePage = () => {
  return (
    <Layout>
      <Home />
      <Apes />
      <Community />
      <Summary />
      <ApeVote />
      <Partners />
    </Layout>
  );
};

export default HomePage;

import { Link } from "react-router-dom";
import SecondaryButton from "../../common/buttons/SecondaryButton";
import TweeterSection from "../../common/slide/TweeterSection";
import texts from "../../../configs/landing.json";

const Community = () => {
  return (
    <section className="px-4 xl:px-10 py-6 relative">
      <div className="w-full bg-blue-dark#6 rounded-[60px] flex flex-col relative z-10">
        <div className="p-8 m-auto text-center">
          <span className="text-center text-primary text-lg font-bold font-tommy">Community</span>
          <h2 className="text-center text-white text-5xl font-bold mt-3 font-tommy">{texts.community.title}</h2>
          <div className="text-center text-slate-400 text-lg font-medium mt-3 font-tommy">
            {texts.community.desc}
          </div>
        </div>
        <TweeterSection />
        <div className="w-full inline-flex justify-center mt-8 mb-8 px-4">
          <Link to={"/weekly"}>
            <SecondaryButton>View Weekly Board</SecondaryButton>
          </Link>
        </div>
      </div>
      <img src="/images/background/planets/planets4.png" alt="planet4" className="absolute -bottom-20 z-0" />
    </section>
  );
};

export default Community;

import { Link } from "react-router-dom";
import SecondaryButton from "../../common/buttons/SecondaryButton";
import PartnersSection from "./PartnersSection";

const Partners = () => {
  return (
    <section className="mt-32 w-full py-6 mb-36">
      <div className="p-4 lg:p-8 m-auto text-center px-4 lg:px-10 ">
        <span className="text-center text-primary text-lg font-bold font-tommy">Worlds</span>
        <h2 className="text-center text-white text-5xl font-bold mt-3 font-tommy">
          Apes Across Worlds is a collective
          <br /> voice shaping its journey,
          <br /> contribution, and shared legacy. {" "}
        </h2>
      </div>
      <div>
        <PartnersSection />
      </div>

      <div className="flex justify-center mt-8 lg:mt-24 px-4">
        <Link to="/partners">
          <SecondaryButton>Explore all Worlds</SecondaryButton>
        </Link>
      </div>
    </section>
  );
};

export default Partners;

import { useEffect } from "react";
import Card from "./Card";
import partners from "../../data/partners.json";

const Partners = () => {
  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="px-4 xl:px-10 py-6 mb-12">
      {" "}
      <div className="p-8 m-auto text-center">
        <span className="text-center text-primary text-lg font-bold font-tommy">Worlds</span>
        <h2 className="text-center text-white text-5xl font-bold mt-3 font-tommy">
          Apes Across Worlds is
          <br /> a collective of worlds.{" "}
        </h2>
        <div className="text-center text-slate-400 text-lg font-medium mt-3 font-tommy">
          Each world based is based off our unique creations and partnerships.
        </div>
      </div>
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 mt-10  m-auto">
          {partners.map((value, index) => (
            <Card
              key={index}
              description={value.description}
              id={value.id}
              name={value.name}
              logo={value.logo}
              website={value.website}
              type={value.type}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;

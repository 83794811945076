import { Link } from "react-router-dom";
import { ProposalDto } from "@justapes/api-types";
import LinkButton from "../../common/buttons/CopyButton";
import AppSection from "../../common/section/appSection";
import StatusCard from "./Status";
import Twitter from "../../../assets/Twitter";
import ArrowLeft from "../../../assets/ArrowLeft";
import formatDate from "../../../utils/formatDate";
import { formatLongNumber } from "../../../utils/format";

const ProposalStatus = ({ proposal }: { proposal: ProposalDto }) => {
  const author =
    proposal.proposer.id.substring(0, 6) + "..." + proposal.proposer.id.substring(proposal.proposer.id.length - 4);

  return (
    <AppSection>
      <div className="flex justify-between flex-col lg:flex-row gap-4">
        <div className="flex flex-col lg:flex-row gap-4 justify-between lg:justify-start">
          <Link to="/app" className="text-white text-base font-bold flex gap-2 ">
            <div className="w-full lg:w-fit px-4 py-3 rounded-2xl border-3 border-blue-dark#3 hover:border-blue-dark#2 justify-center items-center gap-2.5 inline-flex cursor-pointer">
              <ArrowLeft />
              <span>Back</span>
            </div>
          </Link>
          <StatusCard status={proposal.state} />
        </div>
        <div className="flex gap-4 justify-center">
          <LinkButton text="Copy link" link={"https://ja.com/proposal/" + proposal.proposalId} />
          <Link
            to=""
            className="gap-2 px-4 py-2 text-white rounded-2xl border-3 border-blue-dark#2 hover:border-blue-dark#3 active:border-blue-dark#2 flex items-center active:bg-blue-dark#2"
          >
            <Twitter size={24} /> Share on Twitter
          </Link>
        </div>
      </div>
      <div className="flex mt-8 justify-between flex-col lg:flex-row">
        <div className="lg:w-3/5 lg:flex lg:flex-col lg:justify-between">
          <div className="text-white text-3xl font-bold">{proposal.title}</div>
          <div className="justify-start lg:items-center gap-4 flex flex-col lg:flex-row mt-4">
            <ProposalDetails text="Proposal" value={proposal.proposalId} />
            <div className="w-1.5 h-1.5 bg-slate-400 rounded-full hidden lg:block" />
            <ProposalDetails text="Author" value={author} />
            {/* <div className="w-1.5 h-1.5 bg-slate-400 rounded-full hidden lg:block" /> */}
            {/* <ProposalDetails
              text="Txn"
              value={
                <div className="flex gap-1.5 items-center">
                  0xf7...2623
                  <LinkTo to="" />
                </div>
              }
            /> */}
          </div>
        </div>
        <div className="p-6 bg-blue-dark#2 rounded-2xl flex-col justify-center items-start gap-4 inline-flex mt-4 lg:mt-0 lg:w-2/6">
          <div className="self-stretch justify-between items-center  inline-flex">
            <div className="text-slate-400 text-sm font-medium leading-tight">Start date</div>
            <div className="text-white text-sm font-bold">{formatDate(parseInt(proposal.startTs))}</div>
          </div>
          <div className="self-stretch justify-between items-center  inline-flex">
            <div className="text-slate-400 text-sm font-medium leading-tight">End date</div>
            <div className="text-white text-sm font-bold">{formatDate(parseInt(proposal.endTs))}</div>
          </div>
          <div className="self-stretch justify-between items-center  inline-flex">
            <div className="text-slate-400 text-sm font-medium leading-tight">Snapshot</div>
            <div className="justify-start items-center gap-1.5 flex">
              <div className="text-white text-sm font-bold">{formatLongNumber(proposal.blockNumber)}</div>
              <LinkTo to="" />
            </div>
          </div>
        </div>
      </div>
    </AppSection>
  );
};

export default ProposalStatus;

const ProposalDetails = ({ text, value }: { text: string; value: any }) => (
  <div className="justify-between lg:justify-start items-center gap-2 flex ">
    <div className="text-slate-400 text-base font-medium leading-snug">{text}:</div>
    <div className="text-white text-base font-bold">{value}</div>
  </div>
);

const LinkTo = ({ to }: { to: string }) => {
  return (
    <Link to={to}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M12 8.66667V12.6667C12 13.0203 11.8595 13.3594 11.6095 13.6095C11.3594 13.8595 11.0203 14 10.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V5.33333C2 4.97971 2.14048 4.64057 2.39052 4.39052C2.64057 4.14048 2.97971 4 3.33333 4H7.33333"
          stroke="#748FBE"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M10 2H14V6" stroke="#748FBE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M6.66602 9.33333L13.9993 2"
          stroke="#748FBE"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Link>
  );
};

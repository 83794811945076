import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Pagination, EffectCoverflow } from "swiper/modules";
import "swiper/css";
import classNames from "classnames";
import "./partner.css";
import { Link } from "react-router-dom";
import partnersData from "../../../data/partners.json";

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

const SwiperAny = Swiper as any;

const PartnersSection = () => {
  return (
    <SwiperAny
      initialSlide={2}
      draggable={false}
      slidesPerView={6}
      grabCursor={false}
      centeredSlides={true}
      // coverflowEffect={{
      //   rotate: 0,
      //   stretch: 0,
      //   depth: 100,
      //   modifier: 4,
      //   slideShadows: false,
      // }}
      loop={true}
      allowTouchMove={true}
      breakpoints={{
        320: {
          slidesPerView: 1,
        },
        1024: {
          slidesPerView: 5,
        },
        1801: {
          slidesPerView: 6,
        },
      }}
      navigation={{
        nextEl: ".custom-next",
        prevEl: ".custom-prev",
      }}
      scrollbar={{ draggable: false }}
      onNavigationNext={() => console.log("next")}
      //   onSlideChange={(index: number) => setCurrent(index)}
      // onSwiper={(swiper) => console.log(swiper)}
      className="w-full h-fit mt-14 relative"
    >
      {partnersData.map((value, _key) => (
        <SwiperSlide key={_key} className={classNames("!flex justify-center")}>
          <Card
            key={_key}
            description={value.description}
            id={value.id}
            name={value.name}
            logo={value.logo}
            website={value.website}
            type={value.type}
          />
        </SwiperSlide>
      ))}

      <div className="shadow__button active:shadow-none absolute bg-blue-dark#3  lg:right-10 z-10 rounded-full top-0 right-3 bottom-0 m-auto h-14 w-14 custom-next flex justify-center items-center ">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M5.00003 12.0001L19 12.0001"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 5.00012L19 12.0001L12 19.0001"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="shadow__button active:shadow-none absolute bg-blue-dark#3 left-3 lg:left-10 z-10 rounded-full top-0 bottom-0 m-auto h-14 w-14 custom-prev flex justify-center items-center ">
        {" "}
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M19 12.0001L5 12.0001" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M12 5.00012L5 12.0001L12 19.0001"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </SwiperAny>
  );
};

export default PartnersSection;

interface PartnerProps {
  id: string;
  name: string;
  description: string;
  logo: string;
  website: string;
  type: string;
}

const Card = ({ ...props }: PartnerProps) => {
  const { id, logo } = props;
  return (
    <Link
      to={"/partner/" + id.toLocaleLowerCase()}
      className="cursor-pointer w-52 h-52 px-4 py-4 bg-slate-900 rounded-full border-2 border-blue-950 justify-center items-center inline-flex relative partner"
    >
      <div className="arrow_hidden hidden shadow__button active:shadow-none lg:absolute bg-primary right-0 z-10 rounded-full top-0  h-14 w-14 custom-prev justify-center items-center ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className="rotate-[130deg]"
        >
          <path d="M19 12.0001L5 12.0001" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M12 5.00012L5 12.0001L12 19.0001"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <img className="w-44 h-44 hover:opacity-100 opacity-80 rounded-full" src={logo} alt="partner" />
    </Link>
  );
};

import { ProposalsDto, ProposalDto, CreateProposalResponseDto } from "@justapes/api-types";
import { ErrorAPI } from "../types/error";
import { API_URL } from "../configs";

class Proposal {
  static async getProposals(limit: number = 10, offset: number = 0, state: string = "All", title?: string, voter?: string) {
    if (state === "All") state = "";
    else state = "&state=" + state;

    if (title) title = "&title=" + title;
    else title = "";

    if (voter) voter = "&voter=" + voter;
    else voter = "";

    const response = await fetch(API_URL + "/proposal?limit=" + limit + "&offset=" + offset + state + title + voter);
    return response.json() as Promise<ProposalsDto>;
  }

  static async getProposal(id: string) {
    const response = await fetch(API_URL + "/proposal/" + id);
    return response.json() as Promise<ProposalDto>;
  }

  static async getProposalByVoter(voter: string) {
    const response = await fetch(API_URL + "/proposal/byVoter?voter=" + voter + "&limit=100&offset=0");
    return response.json() as Promise<ProposalsDto>;
  }

  static async createProposal(transaction: string) {
    const response = await fetch(API_URL + "/proposal", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ transaction }),
    });
    return response.json() as Promise<CreateProposalResponseDto> | Promise<ErrorAPI>;
  }

  static async getProposalTimeline(id: string) {
    const response = await fetch(API_URL + "/vote/" + id + "/timeline", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.json() as Promise<{ [key: string]: number }>;
  }
}

export default Proposal;

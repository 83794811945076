import Card from "../common/cards/Card";
import tweets from "../../data/community/weekly.json";

const WeeklyBoard = () => {
  return (
    <div className="w-full bg-blue-dark#6 rounded-[60px] flex flex-col pb-8 mt-12">
      <div className="p-8 m-auto text-center">
        <span className="text-center text-primary text-lg font-bold font-tommy">Posts</span>
        <h2 className="text-center text-white text-5xl font-bold mt-3 font-tommy">Weekly Board</h2>
      </div>
      <div className="gap-4 justify-center w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 p-4 ">
        {tweets.map((item, index) => (
          <div className="w-full flex justify-center">
            <Card
              key={index}
              text={item.tweet_text}
              fromImg={item.from_picture}
              fromAuthor={item.from}
              picture={item.picture}
              url={item.url}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeeklyBoard;

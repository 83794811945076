import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Pagination, EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "./slide.css";
import classNames from "classnames";
import Card from "../../common/cards/Card";
import tweets from "../../../data/community/featured.json";

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

const SwiperAny = Swiper as any;

const TweeterSection = () => {
  return (
    <SwiperAny
      initialSlide={2}
      effect="coverflow"
      draggable={false}
      slidesPerView={3}
      grabCursor={false}
      centeredSlides={true}
      coverflowEffect={{
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 4,
        slideShadows: false,
      }}
      allowTouchMove={true}
      breakpoints={{
        320: {
          slidesPerView: 1,
        },
        1024: {
          slidesPerView: 3,
        },
        1801: {
          slidesPerView: 3,
        },
      }}
      navigation={{
        nextEl: ".custom-next",
        prevEl: ".custom-prev",
      }}
      scrollbar={{ draggable: false }}
      onNavigationNext={() => console.log("next")}
      //   onSlideChange={(index: number) => setCurrent(index)}
      // onSwiper={(swiper) => console.log(swiper)}
      className="w-full h-fit mt-4 lg:mt-14 relative"
    >
      {tweets.map((index, _key) => (
        <SwiperSlide key={_key} className={classNames("!flex justify-center custom__slider__tweet")}>
          <Card text={index.tweet_text} fromImg={index.from_picture} fromAuthor={index.from} picture={index.picture} url={index.url}/>
        </SwiperSlide>
      ))}
      <div className="flex justify-center gap-5">
        <div className="cursor-pointer shadow__button active:shadow-none lg:absolute bg-blue-dark#3 left-10 z-10 rounded-full top-0 ml-5 mt-5 bottom-0 lg:m-auto h-14 w-14 custom-prev flex justify-center items-center ">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M19 12.0001L5 12.0001"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 5.00012L5 12.0001L12 19.0001"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="cursor-pointer shadow__button active:shadow-none lg:absolute bg-blue-dark#3  right-10 z-10 rounded-full top-0 bottom-0 mr-5 mt-5 lg:m-auto h-14 w-14 custom-next flex justify-center items-center ">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M5.00003 12.0001L19 12.0001"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 5.00012L19 12.0001L12 19.0001"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </SwiperAny>
  );
};

export default TweeterSection;
